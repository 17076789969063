.locationlistform {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 50px;
}

.filterpropertylist {
  background: #ffffff;
  border-radius: 20px;
  margin-top: 50px;

  max-width: 800px;
  margin-left: 50px;
  padding: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.searchfields {
  margin-left: 50px;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 20px;
}

.propertylistresults {
  background: #ffffff;
  border-radius: 20px;
  width: max-content;
  margin-left: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.download-button {
  display: flex;
  height: 34px;
  width: 180px;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none;
  background-color: rgb(239, 239, 239);
  border-radius: 2px;
  border: 1px solid rgb(118, 118, 118);
  font-size: 16px;
}

.download-button:hover {
  background-color: rgb(229, 229, 229);
}

.download-button:active {
  background-color: rgb(244, 244, 244);
}

.button-group { 
  margin-bottom: 25px;
}