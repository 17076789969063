
#emailinput{

 margin:30px;

}


#userpasswordinput{
  border-bottom: 1px solid rgb(194, 194, 194);


}

#sendverification{
    display: inline-block;
    border: none;
    /* padding: 2rem 2rem; */
    margin-top: 10px;
    text-decoration: none;
    background: #0069ed;
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
  }


  #passwordinstruction{
    text-align: left;
    font-size: 1em;
  }