.portfolio {
  max-height: 400px;
  overflow-y: auto;
}

.controls {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  align-items: end;
  gap: 15px;

  @media (max-width: 824px) {
    grid-template-columns: 1fr 1fr;
  }
}

.divider {
  height: 20px;
  width: 1px;
  border-radius: 5px;
  background-color: var(--border-color-dark);
}