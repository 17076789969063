.nav {
  font-family: var(--font-default);
  min-width: 230px;
  height: 100vh;
}

.rows {
  position: initial;
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: 230px;
  padding-top: 15px;
  background-color: white;
  border-top: 2px solid #F8F8F8;

  .row {
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    display: flex;
    align-items: center;
    color: #212121;
    padding: 15px 0 15px 25px;
    cursor: pointer;
    transition: background-color .2s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      background-color: #fbfbfb;
    }
  
    .icon {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      filter: grayscale(40%);
      transition: all .2s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
}

.highlight {
  background-color: var(--bg-color);
}

.fixRows {
  position: fixed !important;
  top: 0;
  left: 0;
}

