.header {
  --header-font-size: 14px;
  --header-font-weight: 500;
  --header-gaps: 25px;
  
  display: flex;
  align-items: center;
  font-family: var(--font-default);
  width: 100%;
  height: 60px;
  max-height: 66px;

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 900px) {
      display: none;
    }

    .left {
      display: flex;
      margin-left: 25px;
      align-items: center;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: fit-content;
        margin: 0 25px 0 10px;
  
        .logoImage {
          width: 150px;
        }
      }

      .navigation {
        display: flex;
        align-items: center;
        gap: var(--header-gaps);
        font-weight: var(--header-font-weight) !important;



        .selected {
          &::before {
            transform: scaleX(1) !important;
          }

          &:hover::before {
            transform: scaleX(.8) !important;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      margin-right: 25px;
      gap: var(--header-gaps);

      .dropdowns {
        display: flex;
        gap: var(--header-gaps);
      }

      .signout {
        width: 40px;
        height: 40px;
      }
    }
  }

  .smallContainer {
    display: none;
    justify-content: space-between;
    width: 100%;
    margin: 0 25px;

    @media (max-width: 900px) {
      display: flex;
    }

    .smallLogo {
      width: 120px;
    }

    .smallLeft {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
}

.item {
  position: relative;
  font-size: var(--header-font-size);
  font-weight: var(--header-font-weight);
  font-family: var(--font-default);
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0px;
    height: 1px;
    width: 100%;
    background-color: white;
    transform: scaleX(0);
    transition: .2s all cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover::before {
    transform: scaleX(.3);
  }
}
