.input__switch {
  display: none;
}

.input__switch_label {
  clear: right;
  cursor: pointer;
  text-indent: -9999px;
  width: 42px;
  height: 22px;
  background: rgba(51, 51, 51, 0.3);
  display: inline-block;
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 2px;
  position: relative;
  vertical-align: middle;
}

.input__switch_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 20px;
  height: 20px;
  background: rgba(235, 235, 235, 1);
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 2px;
  box-sizing: border-box;
  transition: 0.3s;
}

.input__switch:checked + .input__switch_label {
  background: rgba(70, 206, 28, 1);
}

.input__switch:checked + .input__switch_label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.button__close {
  position: absolute;
  width: 37px;
  height: 33px;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0);
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23000000%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E%0A);
  background-size: 13px 13px;
  background-position: center;
  background-repeat: no-repeat;

  opacity: 0.7;
}

.button__close:hover {
  opacity: 1;
}
