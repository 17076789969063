.notice__error {
  position: absolute;
  left: 732px;
  top: 451px;
  font-weight: bold;
}

.addobjects {
  position: absolute;
  right: 150px;
  top: 230px;
}

.dropdown-item {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.dropdown-item:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-item:focus {
  color: #eeeeee;
  text-decoration: none;
  background: #0652dd;
}

.riskReportWrap {
  display: flex;
  background-color: var(--bg-color);
}

.riskPagesWrap {
  margin: 20px 0 0 20px;
  display: flex;
  flex-flow: column;
  gap: 15px;
}

.riskReportContainer {
  display: flex;
  flex-flow: column;
  gap: 15px;
}