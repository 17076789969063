.property {
  min-height: 280px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  
  &__header {
    background: #e5e5e5;
    padding: 10px 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;

    > .small {
      font-size: 12px;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
    margin: 40px 60px;
  }
}


.detail {
  width: 380px;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 130px 230px;

  &__label {
    padding-top: 5px;
    font-size: 18px;
    color: #474747;
  }

  &__container {
    padding: 8px 13px;
    background-color: #F4F4F4;
    border-radius: 5px;
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
  }

  
  &--rows-2 {
    grid-row: span 2;
  }

  &--rows-3 {
    grid-row: span 3;
  }
}