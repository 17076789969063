.dropdown {
  text-align: center;
  margin: 0;
  
  button {  
    outline: 0;
    border: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.text {
  color: var(--font-color);
}

.lightText {
  color: white;
}

.lightBackground {
  background-color: white !important;
}

.textToggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 5px;

  .arrow {
    height: 10px;
    width: 10px;
    background-color: var(--font-color);
    clip-path: polygon(50% 75%, 90% 32%, 100% 40%, 50% 95%, 0 40%, 10% 32%);
  }
}

.headerToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .15);
  cursor: pointer;
  height: 24px;
  width: 24px;
  transition: .1s background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-radius: 4px;
  
  &:hover {
    background-color: rgba(0, 0, 0, .25);
  }

  .icon {
    height: 14px;
    width: 14px;
  }
}

.burgerToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  .burger {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    & > span {
      width: 25px;
      height: 1px;
      background-color: white;
    }
  }
}

.optionToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  
  &:hover {
    color: rgba(0, 0, 0, .25);
  }

  .icon {
    height: 14px;
    width: 14px;
  }
}