.assignuserform {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 50px;
}

.filterproperty {
  max-width: 800px;
  margin-left: 50px;
  padding: 20px;
  border-bottom: 1px solid rgb(194, 194, 194);
}

.searchfields {
  margin-left: 50px;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 20px;
}

.searchfields button {
  margin-top: 0;
}

.assignuser {
  margin-right: 20px;
  display: grid;
  grid-template-columns: 3fr 0.8fr 0.5fr 0.8fr;
  grid-column-gap: 15px;
}

.reassignuser {
  margin-right: 20px;
  display: grid;
  grid-template-columns: 3fr 0.8fr 2fr;
  grid-column-gap: 15px;
}

.propertylist {
  background: #ffffff;
  border-radius: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.userlist {
  background: #dddddd;
  border-radius: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.userlist2 {
  background: #ffffff;
  border-radius: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.table-smaller {
  width: auto;
  margin: 30px;
  float: left;
}

.table-smaller-locations {
  margin: 30px;
  float: left;
}
.table-smaller-users {
  margin: 30px;
  float: left;
}

.table-smaller2 {
  width: auto;
  margin: 30px;
  float: left;
}
.AssignUsers p {
  margin-top: 100px;
}

.navigation {
  width: 100%;
  clear: both;
  background-color: #ffffff;
  text-align: center;
  margin-top: 100px;
}
.col-sm-2 {
  margin-left: 20px;
}

.btn-center {
  width: auto;
  margin: 30px;
  margin-top: 90px;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-arround;
  align-items: flex-start;
}

.gridview .selected,
.gridview tbody .selected {
  background-color: #d3d3d3;
}

.filter_type,
.filter_string {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.filter_type:focus,
.filter_string:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
