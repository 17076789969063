// Input
.container {
  display: flex;
  flex-flow: column;
  gap: 5px;
  min-width: 200px;

  .label {
    color: var(--font-color);
    font-size: 15px;
    font-weight: 400;
  }

  .error {
    font-size: 10px;
    font-weight: 400;
    color: #ff746d;
  }

  .input {
    color: #363636;
    width: 100%;
    background-color: white;
    height: 40px;
    outline: 0;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--border-color-light);
    padding-left: 12px;
    font-size: 15px;
    

    &:focus {
      border: solid 1px var(--focus-color);
    }

    &::placeholder {
      color: #d4d4d4;
    }
  }
}

// Input Added

.inputWrapper {
  position: relative;
  width: 100%;
}

.hideButton {
  display: none;
}

.addButton {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
