.sidebar {
  position: relative;
  right: 0;
  width: 100%;
  height: 100px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
  border-left: 1px solid rgba(51, 51, 51, 0.25);
  box-shadow: -1px 0 4px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, auto);
}
