.locationlistform {
  display: grid;
  grid-template-rows: auto;
  /* grid-row-gap: 50px; */
  justify-content: center;
}

.filterpropertylist {
  background: #ffffff;
  margin: 50px 0 0 0;
  border-radius: 20px;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.searchfields {
  margin-left: 50px;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 20px;
}

.propertylistresults {
  background: #ffffff;
  border-radius: 20px;
  margin: 0;
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.button {
  border: none;
  height: 34px;
  padding: 1px 10px;
  border-radius: 5px;
  background-color: #417dea;
  color: white;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.button:hover {
  background-color: #0652dd;
}

.button-group {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  column-gap: 20px;
}