.assignlocationform {
  /* display: grid; */
  /* grid-template-rows: auto auto auto; */
  grid-row-gap: 50px;
}

.filterusers {
  background: #ffffff;
  border-radius: 20px;
  max-width: 800px;
  margin-left: 50px;
  padding: 20px;
  /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09); */
}

.usersearchfields {
  margin-left: 50px;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 20px;
}

.propertylist {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.userlist2 {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Assignuserstable {
  margin-left: 80px;
  display: grid;
  grid-template-columns: 30% 20% 30%;
}

.assignlocationform button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.assignlocationform button:hover,
.assignlocationform button:focus {
  background: #0053ba;
}

.assignlocationform button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.assignlocationform button:active {
  transform: scale(0.99);
}
