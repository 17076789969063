@import url(https://fonts.googleapis.com/css?family=Lato);
.Select_container__tfVtX {
  display: flex;
  flex-flow: column;
  grid-gap: 5px;
  gap: 5px;
  min-width: 200px; }
  .Select_container__tfVtX .Select_label__TjpcD {
    color: var(--font-color);
    font-size: 15px;
    font-weight: 400; }
  .Select_container__tfVtX .Select_error__xI8ma {
    font-size: 10px;
    font-weight: 400;
    margin-left: 5px;
    color: #ff746d; }
  .Select_container__tfVtX .Select_input__1Yzza {
    color: #363636;
    background-color: white;
    min-height: 40px;
    outline: 0;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--border-color-light);
    font-size: 15px; }
    .Select_container__tfVtX .Select_input__1Yzza > div {
      border: 0;
      border-radius: var(--input-border-radius);
      box-shadow: 0; }
    .Select_container__tfVtX .Select_input__1Yzza:-ms-input-placeholder {
      color: #d4d4d4; }
    .Select_container__tfVtX .Select_input__1Yzza::placeholder {
      color: #d4d4d4; }

.Input_container__2cYws {
  display: flex;
  flex-flow: column;
  grid-gap: 5px;
  gap: 5px;
  min-width: 200px; }
  .Input_container__2cYws .Input_label__xV2Ht {
    color: var(--font-color);
    font-size: 15px;
    font-weight: 400; }
  .Input_container__2cYws .Input_error__2C5wM {
    font-size: 10px;
    font-weight: 400;
    color: #ff746d; }
  .Input_container__2cYws .Input_input__varAs {
    color: #363636;
    width: 100%;
    background-color: white;
    height: 40px;
    outline: 0;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--border-color-light);
    padding-left: 12px;
    font-size: 15px; }
    .Input_container__2cYws .Input_input__varAs:focus {
      border: solid 1px var(--focus-color); }
    .Input_container__2cYws .Input_input__varAs:-ms-input-placeholder {
      color: #d4d4d4; }
    .Input_container__2cYws .Input_input__varAs::placeholder {
      color: #d4d4d4; }

.Input_inputWrapper__qQmkL {
  position: relative;
  width: 100%; }

.Input_hideButton__2WdIZ {
  display: none; }

.Input_addButton__2S8IM {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%); }

.Button_button__23ZZT {
  display: inline-flex;
  font-family: var(--font-default);
  justify-content: center;
  align-items: center;
  min-width: 125px;
  padding: 0 25px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: var(--theme-primary);
  cursor: pointer;
  transition: 0.1s all cubic-bezier(0.075, 0.42, 0.665, 1); }
  .Button_button__23ZZT:hover {
    filter: brightness(120%); }

.Button_large__1kyW_ {
  height: 48px; }

.Button_normal__3ALR5 {
  height: 35px; }

.Button_small__28Ysr {
  height: 28px;
  min-width: 55px;
  font-size: 12px;
  font-weight: 400; }

.Button_tiny__2Utq5 {
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 400;
  min-width: 0; }

.Button_wide__awGBS {
  display: flex; }

.Button_light__xF9ML {
  background-color: var(--theme-light);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color-light); }
  .Button_light__xF9ML:hover {
    filter: brightness(98%); }

.Button_disabled__3ndak {
  filter: grayscale(30%) brightness(80%);
  opacity: .7; }
  .Button_disabled__3ndak:hover {
    filter: grayscale(30%) brightness(80%);
    opacity: .7; }

.Button_light__xF9ML.Button_disabled__3ndak {
  filter: grayscale(0%) brightness(90%); }
  .Button_light__xF9ML.Button_disabled__3ndak:hover {
    filter: grayscale(0%) brightness(90%); }

.Button_downloadButton__2oTeY {
  color: inherit !important;
  text-decoration: none !important; }

.UserInput_user__3MMV1 {
  display: flex;
  flex-flow: column;
  grid-gap: 15px;
  gap: 15px; }
  .UserInput_user__3MMV1 .UserInput_row_1__2YqGh {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    gap: 15px; }
  .UserInput_user__3MMV1 .UserInput_row_2__S9L87 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    gap: 15px; }

label {
  margin: 10px;
}
.balkerne button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.balkerne button:hover,
.balkerne button:focus {
  background: #0053ba;
}

.balkerne button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.balkerne button:active {
  transform: scale(0.99);
}

.AssignUsers_portfolio__12DYE {
  max-height: 400px;
  overflow-y: auto; }

.AssignUsers_controls__1KdFj {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  align-items: end;
  grid-gap: 15px;
  gap: 15px; }
  @media (max-width: 824px) {
    .AssignUsers_controls__1KdFj {
      grid-template-columns: 1fr 1fr; } }

.AssignUsers_divider__3bDRc {
  height: 20px;
  width: 1px;
  border-radius: 5px;
  background-color: var(--border-color-dark); }

.AssignUsersLastStep p {
  margin-top: 100px;
}

.navigation {
  width: 100%;
  clear: both;
  background-color: #ffffff;
  text-align: center;
  margin-top: 100px;
}
.btn-lg {
  clear: both;
  background-color: #ffffff;
  text-align: center;
  margin-top: 100px;
}

.col-sm-2 {
  margin-left: 30px;
}
.Userlaststep {
  margin-top: 50px;

  margin-left: 31%;
  display: inline-grid;
  grid-template-rows: auto auto;
  text-align: center;
}

.Userlaststep button {
  display: inline-block;
  border: none;
  padding: 1rem 4rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: rgb(8, 143, 8);
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.Userlaststep button:hover,
.Userlaststep button:focus {
  background: rgba(8, 143, 8, 0.692);
}

.Userlaststep button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.DropDown_dropdown__JsoWh {
  text-align: center;
  margin: 0; }
  .DropDown_dropdown__JsoWh button {
    outline: 0;
    border: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0) !important; }

.DropDown_text__1i9ln {
  color: var(--font-color); }

.DropDown_lightText__zvMpf {
  color: white; }

.DropDown_lightBackground__3jatD {
  background-color: white !important; }

.DropDown_textToggle__2g9-i {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 5px;
  gap: 5px; }
  .DropDown_textToggle__2g9-i .DropDown_arrow__1XerL {
    height: 10px;
    width: 10px;
    background-color: var(--font-color);
    -webkit-clip-path: polygon(50% 75%, 90% 32%, 100% 40%, 50% 95%, 0 40%, 10% 32%);
            clip-path: polygon(50% 75%, 90% 32%, 100% 40%, 50% 95%, 0 40%, 10% 32%); }

.DropDown_headerToggle__tbZBE {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;
  height: 24px;
  width: 24px;
  transition: 0.1s background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-radius: 4px; }
  .DropDown_headerToggle__tbZBE:hover {
    background-color: rgba(0, 0, 0, 0.25); }
  .DropDown_headerToggle__tbZBE .DropDown_icon__3_QzR {
    height: 14px;
    width: 14px; }

.DropDown_burgerToggle__ga7dd {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .DropDown_burgerToggle__ga7dd .DropDown_burger__2Bths {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px; }
    .DropDown_burgerToggle__ga7dd .DropDown_burger__2Bths > span {
      width: 25px;
      height: 1px;
      background-color: white; }

.DropDown_optionToggle__21G4T {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 4px; }
  .DropDown_optionToggle__21G4T:hover {
    color: rgba(0, 0, 0, 0.25); }
  .DropDown_optionToggle__21G4T .DropDown_icon__3_QzR {
    height: 14px;
    width: 14px; }

.Header_header__3YITx {
  --header-font-size: 14px;
  --header-font-weight: 500;
  --header-gaps: 25px;
  display: flex;
  align-items: center;
  font-family: var(--font-default);
  width: 100%;
  height: 60px;
  max-height: 66px; }
  .Header_header__3YITx .Header_container__3y6SA {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 900px) {
      .Header_header__3YITx .Header_container__3y6SA {
        display: none; } }
    .Header_header__3YITx .Header_container__3y6SA .Header_left__1Yz5g {
      display: flex;
      margin-left: 25px;
      align-items: center; }
      .Header_header__3YITx .Header_container__3y6SA .Header_left__1Yz5g .Header_logo__3M5n2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 25px 0 10px; }
        .Header_header__3YITx .Header_container__3y6SA .Header_left__1Yz5g .Header_logo__3M5n2 .Header_logoImage__3zeOx {
          width: 150px; }
      .Header_header__3YITx .Header_container__3y6SA .Header_left__1Yz5g .Header_navigation__euYvb {
        display: flex;
        align-items: center;
        grid-gap: var(--header-gaps);
        gap: var(--header-gaps);
        font-weight: var(--header-font-weight) !important; }
        .Header_header__3YITx .Header_container__3y6SA .Header_left__1Yz5g .Header_navigation__euYvb .Header_selected__3fLCL::before {
          transform: scaleX(1) !important; }
        .Header_header__3YITx .Header_container__3y6SA .Header_left__1Yz5g .Header_navigation__euYvb .Header_selected__3fLCL:hover::before {
          transform: scaleX(0.8) !important; }
    .Header_header__3YITx .Header_container__3y6SA .Header_right__-z_HN {
      display: flex;
      align-items: center;
      margin-right: 25px;
      grid-gap: var(--header-gaps);
      gap: var(--header-gaps); }
      .Header_header__3YITx .Header_container__3y6SA .Header_right__-z_HN .Header_dropdowns__2D8Ke {
        display: flex;
        grid-gap: var(--header-gaps);
        gap: var(--header-gaps); }
      .Header_header__3YITx .Header_container__3y6SA .Header_right__-z_HN .Header_signout__3jRc0 {
        width: 40px;
        height: 40px;
        cursor: pointer; }
  .Header_header__3YITx .Header_smallContainer__2wv68 {
    display: none;
    justify-content: space-between;
    width: 100%;
    margin: 0 25px; }
    @media (max-width: 900px) {
      .Header_header__3YITx .Header_smallContainer__2wv68 {
        display: flex; } }
    .Header_header__3YITx .Header_smallContainer__2wv68 .Header_smallLogo__j_enk {
      width: 120px; }
    .Header_header__3YITx .Header_smallContainer__2wv68 .Header_smallLeft__VC56W {
      display: flex;
      align-items: center;
      grid-gap: 15px;
      gap: 15px; }

.Header_item__1tWDx {
  position: relative;
  font-size: var(--header-font-size);
  font-weight: var(--header-font-weight);
  font-family: var(--font-default);
  text-decoration: none;
  color: white; }
  .Header_item__1tWDx:hover {
    color: white; }
  .Header_item__1tWDx::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0px;
    height: 1px;
    width: 100%;
    background-color: white;
    transform: scaleX(0);
    transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1); }
  .Header_item__1tWDx:hover::before {
    transform: scaleX(0.3); }

.loader {
  margin-left: calc(50% - 25px);
  margin-top: calc(25% - 25px);
}

.Container_container__3OiZ8 {
  padding: 0 35px 55px 35px;
  width: 100%; }

.Container_centerContainer__1NuUL {
  display: flex;
  width: 100%;
  justify-content: center; }

.Container_center__1zY59 {
  margin-top: 20px;
  width: 950px;
  max-width: 950px; }

.Container_centerResponsive__kGfsl {
  margin-top: 20px;
  width: 100%;
  max-width: 950px; }

.PreTitle_pretitle__3HGW4 {
  letter-spacing: .8px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #95AAC9; }

.PageHeader_header__1s-tJ {
  display: flex;
  align-items: center;
  min-height: 80px;
  width: 100%;
  border-bottom: 1px solid var(--border-color-light);
  margin-bottom: 25px; }

.PageHeader_title__QyUnq {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  flex-grow: 1; }

.adduserpage {
  background: #ffffff;
  border-radius: 20px;
  margin-top: 50px;
  min-width: 900px;

  margin-left: 5%;
  width: 90%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.addusersheading {
  background: #e5e5e5a1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.addusersheading h2 {
  font-weight: 400;
  padding: 0px 5px;
  margin-left: 20px;
}

.addusersheading p {
  text-align: right;
  margin-right: 30px;
}

.adduserbutton {
  margin-left: 40px;
}

.removeuserbutton {
  margin-left: 40px;
}

.adduserpage button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.adduserpage button:hover,
.adduserpage button:focus {
  background: #0053ba;
}

.adduserpage button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.adduserpage button:active {
  transform: scale(0.99);
}

.map {
  height: 100%;
}

.info_window {
  font-family: var(--font-default);
  font-size: 14px;
  font-weight: 14px;
  line-height: 1.4;
  color: rgba(51, 51, 51, 1);
}

.info_window > *:first-child {
  margin-top: 0;
}

.info_window > *:last-child {
  margin-bottom: 0;
}

.info_window__heading {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.info_window__subheading {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
}

.info_window__text--no-space {
  margin-top: 0;
}

.info_window .dashboardButton {
  width: 100%;
  background-color: rgb(6, 82, 221);
  color: white;
  border: 0;
  outline: 0;
  border-radius: 19px;
  padding: 5px 0;
}

.AddMap_map__3kMZe {
  height: 100%;
  width: 100%;
  border-radius: var(--card-border-radius); }

.AddMap_map_container__XzZlu {
  height: 400px;
  width: 100%;
  margin: 6px 0; }

.AddMap_input_grid__3bFTI {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 6px; }

.assignlocationform {
  /* display: grid; */
  /* grid-template-rows: auto auto auto; */
  grid-row-gap: 50px;
}

.filterusers {
  background: #ffffff;
  border-radius: 20px;
  max-width: 800px;
  margin-left: 50px;
  padding: 20px;
  /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09); */
}

.usersearchfields {
  margin-left: 50px;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 20px;
}

.propertylist {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.userlist2 {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Assignuserstable {
  margin-left: 80px;
  display: grid;
  grid-template-columns: 30% 20% 30%;
}

.assignlocationform button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.assignlocationform button:hover,
.assignlocationform button:focus {
  background: #0053ba;
}

.assignlocationform button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.assignlocationform button:active {
  transform: scale(0.99);
}

.LocationAssignUsers_filter__ebHQl {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  align-items: flex-end;
  grid-gap: 15px;
  gap: 15px; }
  @media (max-width: 816px) {
    .LocationAssignUsers_filter__ebHQl {
      grid-template-columns: 1fr 1fr; } }

.addlocationform {
  background: #ffffff;
  border-radius: 20px;
  width: 80%;
  min-width: 900px;
  margin-top: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  margin-left: 10%;
}

.addlocationheading {
  background: #e5e5e5a1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.addlocationheading h2 {
  font-weight: 400;
  padding: 0px 5px;
  margin-left: 20px;
}

.addlocationheading p {
  text-align: right;
  margin-right: 20px;
}

.addlocationform button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.addlocationform button:hover,
.addlocationform button:focus {
  background: #0053ba;
}

.addlocationform button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.addlocationform button:active {
  transform: scale(0.99);
}

.delete-menu {
    position: absolute;
    background: white;
    padding: 3px;
    color: #666;
    font-weight: bold;
    border: 1px solid #999;
    font-family: sans-serif;
    font-size: 12px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, .3);
    margin-top: -10px;
    margin-left: 10px;
    cursor: pointer;
  }
  .delete-menu:hover {
    background: #eee;
  }
.HeaderProperty_header__1zdys {
  --header-font-size: 14px;
  --header-font-weight: 500;
  --header-gaps: 25px;
  display: flex;
  align-items: center;
  font-family: var(--font-default);
  width: 100%;
  height: 60px;
  max-height: 66px; }
  .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 900px) {
      .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF {
        display: none; } }
    .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_left__3slKN {
      display: flex;
      margin-left: 25px;
      align-items: center; }
      .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_left__3slKN .HeaderProperty_logo__2j81e {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 25px 0 10px; }
        .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_left__3slKN .HeaderProperty_logo__2j81e .HeaderProperty_logoImage__1LL0S {
          width: 150px; }
      .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_left__3slKN .HeaderProperty_navigation__3MOrW {
        display: flex;
        align-items: center;
        grid-gap: var(--header-gaps);
        gap: var(--header-gaps);
        font-weight: var(--header-font-weight) !important; }
        .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_left__3slKN .HeaderProperty_navigation__3MOrW .HeaderProperty_selected__2nTme::before {
          transform: scaleX(1) !important; }
        .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_left__3slKN .HeaderProperty_navigation__3MOrW .HeaderProperty_selected__2nTme:hover::before {
          transform: scaleX(0.8) !important; }
    .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_right__j_hoK {
      display: flex;
      align-items: center;
      margin-right: 25px;
      grid-gap: var(--header-gaps);
      gap: var(--header-gaps); }
      .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_right__j_hoK .HeaderProperty_dropdowns__9L8s8 {
        display: flex;
        grid-gap: var(--header-gaps);
        gap: var(--header-gaps); }
      .HeaderProperty_header__1zdys .HeaderProperty_container__BnztF .HeaderProperty_right__j_hoK .HeaderProperty_signout__2kRdU {
        width: 40px;
        height: 40px; }
  .HeaderProperty_header__1zdys .HeaderProperty_smallContainer__1pczR {
    display: none;
    justify-content: space-between;
    width: 100%;
    margin: 0 25px; }
    @media (max-width: 900px) {
      .HeaderProperty_header__1zdys .HeaderProperty_smallContainer__1pczR {
        display: flex; } }
    .HeaderProperty_header__1zdys .HeaderProperty_smallContainer__1pczR .HeaderProperty_smallLogo__1mw0g {
      width: 120px; }
    .HeaderProperty_header__1zdys .HeaderProperty_smallContainer__1pczR .HeaderProperty_smallLeft__3zvd9 {
      display: flex;
      align-items: center;
      grid-gap: 15px;
      gap: 15px; }

.HeaderProperty_item__86vpI {
  position: relative;
  font-size: var(--header-font-size);
  font-weight: var(--header-font-weight);
  font-family: var(--font-default);
  text-decoration: none;
  color: white; }
  .HeaderProperty_item__86vpI:hover {
    color: white; }
  .HeaderProperty_item__86vpI::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0px;
    height: 1px;
    width: 100%;
    background-color: white;
    transform: scaleX(0);
    transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1); }
  .HeaderProperty_item__86vpI:hover::before {
    transform: scaleX(0.3); }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  overflow-y: scroll;
  overflow-x: hidden;
}
.formbackground {
  position: absolute;
  left: 25%;
  width: 600px;
  top: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}

.formcontent {
  margin-left: 10%;
  margin-top: 30px;
  max-width: 70%;
}

.form-group {
  margin-bottom: 30px;
}
.form-group button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-group button:hover,
.form-group button:focus {
  background: #0053ba;
}

.form-group button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.form-group button:active {
  transform: scale(0.99);
}

.form-group-date {
  display: block;
}

.react-datepicker-wrapper {
  border-radius: 5px;
  margin-bottom: 20px;
}

.react-datepicker {
  transform: scale(1.5, 1.5);
  transform-origin: left top;
}
.submitbutton {
  text-align: center;
}

.close_cross_button {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 0;
  border: none;
  background: none;
}
.alert-success-small {
  display: inline-block !important;
  width: 450px;
  color: green;
  text-align: center;
}
.alert-danger-small {
  display: inline-block !important;
  width: 450px;
  color: red;
  text-align: center;
}

.input__switch {
  display: none;
}

.input__switch_label {
  clear: right;
  cursor: pointer;
  text-indent: -9999px;
  width: 42px;
  height: 22px;
  background: rgba(51, 51, 51, 0.3);
  display: inline-block;
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 2px;
  position: relative;
  vertical-align: middle;
}

.input__switch_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 20px;
  height: 20px;
  background: rgba(235, 235, 235, 1);
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 2px;
  box-sizing: border-box;
  transition: 0.3s;
}

.input__switch:checked + .input__switch_label {
  background: rgba(70, 206, 28, 1);
}

.input__switch:checked + .input__switch_label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.button__close {
  position: absolute;
  width: 37px;
  height: 33px;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0);
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23000000%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E%0A);
  background-size: 13px 13px;
  background-position: center;
  background-repeat: no-repeat;

  opacity: 0.7;
}

.button__close:hover {
  opacity: 1;
}

.option__category {
  padding: 0px 10px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.25);
  line-height: 1.4;
  color: rgba(51, 51, 51, 1);
  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.option__category--dashboard {
  border-top: none !important;
  border-bottom: none !important;
  border-radius: 5px;
}

.option__category > summary + * {
  margin-left: 20px;
}

.option__category > summary {
  display: grid;
  grid-template-columns: 25px auto 60px;
}

.option__category p {
  margin: 10px;
  vertical-align: middle;
}
.option__heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.option__heading--single {
  cursor: auto !important;
  display: grid;
  grid-template-columns: auto 60px;
}

.option__heading:active,
.option__heading:focus {
  outline: none;
}

.option__heading--empty {
  list-style: none;
  /* display: grid;
  grid-template-columns: auto 60px; */
}

.option__list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.option__item {
  margin-bottom: 0.5em;

  font-size: 14px;

  display: grid;
  grid-template-columns: auto 60px;
}

.option__item:last-child {
  margin-bottom: 0;
}

.option__icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

.option__icon--box {
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 2px;
}

.PropertyDashboard {
  background-color: var(--bg-color);
}

.dashboard_right {
  display: grid;
  grid-template-rows: repeat(3, -webkit-max-content);
  grid-template-rows: repeat(3, max-content);
  height: -webkit-max-content;
  height: max-content;
  grid-row-gap: 30px;
  margin-right: 20px;
  /* margin-left: 30px; */
}

.dashboard_left {
  display: grid;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  height: -webkit-max-content;
  height: max-content;
  grid-column-gap: 5%;
}

.test_dashboard__risk {
  width: 250px;
  height: 800px;

  background: #ffffff;
  border-radius: 5px;

  box-sizing: border-box;
  /* margin-bottom: 20px; */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.dashboard__risk_breakdown {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-between;
}

.dashboard__risk_score_title {
  font-size: 17px;
  /* font-weight: bold; */
  padding: 5px 30px;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 2px solid rgba(139, 217, 254, 1);
  width: 100%;
}
.dashboard__risk_score {
  /* line-height: 20px; */
  margin-top: 0;
}

.dashboard__risk_score--main {
  font-size: 50px;
  text-align: center;
  width: 100%;
}
.dashboard__risk_score--small {
  font-size: 32px;
}

.dashboard__risk_score--sub {
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  margin: 10px;
  display: grid;
  grid-template-rows: auto auto;
}
#increasedrating {
  color: red;
  font-size: 30px;
}
.dashboard__risk_breakdown {
  margin-top: 10px;

  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  grid-row-gap: 5px;
  row-gap: 5px;
}

.dashboard__risk_category {
  display: inline;
}

.dashboard__risk_bar {
  -webkit-appearance: none;
  appearance: none;
}
.dashboard__risk_score--green {
  color: rgba(67, 162, 59, 1);
}

#rating.dashboard__risk_bar[value] {
  flex: 0 0 auto;
  height: 15px;
  width: 180px;
  border: none;
  box-sizing: border-box;
}

#demoscore.dashboard__risk_bar[value] {
  flex: 0 0 auto;
  height: 10px;
  width: 180px;
  border: none;
  box-sizing: border-box;
}

.dashboard__risk_bar--low[value] {
  background-color: rgba(67, 162, 59, 0.25);
}

.dashboard__risk_bar--medium[value] {
  background-color: rgba(237, 150, 0, 0.25);
}

.dashboard__risk_bar--high[value] {
  background-color: rgba(229, 41, 34, 0.25);
}

.dashboard__risk_bar--low[value]::-moz-progress-bar {
  background-color: rgba(67, 162, 59, 1);
}

.dashboard__risk_bar--medium[value]::-moz-progress-bar {
  background-color: rgba(237, 150, 0, 1);
}

.dashboard__risk_bar--high[value]::-moz-progress-bar {
  background-color: rgba(229, 41, 34, 1);
}

.dashboard__risk_bar--low[value]::-webkit-progress-value {
  background-color: rgba(67, 162, 59, 1);
}

.dashboard__risk_bar--medium[value]::-webkit-progress-value {
  background-color: rgba(237, 150, 0, 1);
}

.dashboard__risk_bar--high[value]::-webkit-progress-value {
  background-color: rgba(229, 41, 34, 1);
}
/* .dashboard__map {
  min-width: 400px;
  z-index: 1;
  border-radius: 2px;
  border: 1px solid rgba(51, 51, 51, 0.25);
  box-sizing: border-box;
  overflow: hidden;
} */

.progress {
  /* margin-top: 5px; */
  height: 5px;
  padding: 0;
  margin-bottom: 0px;
}
#dashboardmap{
  max-width: 700px;

}
.dashboard__map {
  height: 600px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  border: 1px solid rgba(51, 51, 51, 0.25);
  box-sizing: border-box;
  overflow: hidden;
}
.dashboard_map_area {
  width: 100%;
  height: 700px;
  left: 20px;

  padding: 5px 5px;

  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}

.dashboard__map_controls {
  /* position: absolute; */
  left: 20px;
  /* top: 900px; */
  height: 40px;
  /* background-color: rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.dashboard__alerts {
  /* position: absolute; */
  width: 100%;
  left: 570px;
  /* top: 265px; */
  height: 250px;

  overflow-y: scroll;
  overflow-x: hidden;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Alertsriskreport {
  /* position: relative; */
  width: 127px;
  height: 25px;
  float: right;
  margin-top: 0px;
  background: #0652dd;
  border-radius: 10px;

  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}

.Alertsriskreport:hover {
  background: #2166e6;
}

.Mitigationsriskreport {
  /* position: relative; */
  width: 127px;
  height: 25px;
  float: right;
  margin-top: 0px;
  background: #0652dd;
  border-radius: 10px;

  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
}

.Mitigationsriskreport:hover {
  background: #2166e6;
}

.mitigationurl {
  cursor: pointer;
}


.dashboard__recommendations {
  /* position: absolute; */
  width: 100%;
  left: 570px;
  top: 500px;
  height: 215px;
  overflow-y: scroll;
  overflow-x: hidden;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.dashboard-card-body {
  overflow-y: auto;
  padding: 12px 4px 12px 0;
  height: min(-webkit-fit-content, 210px);
  height: min(-moz-fit-content, 210px);
  height: min(fit-content, 210px);
}

.dashboard__mitigation {
  /* position: absolute; */
  width: 100%;
  overflow: auto;
  left: 570px;
  top: 735px;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dashboard__notices_title {
  font-size: 20px;
  font-weight: bold;
  padding: 5px 30px;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  flex: 0 1;
}

.dashboard__notices_title--recommendations {
  border-bottom: 2px solid rgba(255, 203, 127, 1);
}

.dashboard__notices_title--alerts {
  border-bottom: 2px solid rgba(230, 148, 146, 1);
}

.dashboard__notices_title--activity {
  border-bottom: 2px solid rgba(133, 153, 157, 1);
}

.notice__box {
  margin-left: 10px;
  flex: 0 0;
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.notice__icon {
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  background-color: rgba(0, 0, 0, 0.1);
}

.notice__content {
  display: flex;
  flex-direction: column;
}

.notice__title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.notice__text {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.dashboardcontent {
  background-color: var(--bg-color);
  margin-bottom: 50px;
  margin-left: 20px;
  grid-column-gap: 25px;
}

@media (min-width: 1200px) {
  .dashboardcontent {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2,50%);
    width: calc(100% - 40px);
  }
}

@media (max-width: 1201px) {
  .dashboardcontent {
    position: relative;
    top: 60px;
    width: calc(100% - 20px);
  }

  #dashboardleft {
    display: none;
  }

  #viewmore {
    display: none;
  }
}

.PropertySubHeader_header__1duiQ {
  font-family: var(--font-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 27px;
  height: 85px;
  width: 100%;
  background-color: white; }
  .PropertySubHeader_header__1duiQ .PropertySubHeader_company__1LofW {
    display: flex;
    flex-flow: column;
    grid-gap: 10px;
    gap: 10px; }
    .PropertySubHeader_header__1duiQ .PropertySubHeader_company__1LofW .PropertySubHeader_property__2NyyZ {
      font-size: 22px;
      font-weight: 600;
      color: var(--font-color); }
    .PropertySubHeader_header__1duiQ .PropertySubHeader_company__1LofW .PropertySubHeader_details__O_AkZ {
      display: flex;
      grid-gap: 20px;
      gap: 20px; }
      .PropertySubHeader_header__1duiQ .PropertySubHeader_company__1LofW .PropertySubHeader_details__O_AkZ .PropertySubHeader_detail__28gmY {
        display: flex;
        align-items: center;
        color: var(--font-color-muted); }
        .PropertySubHeader_header__1duiQ .PropertySubHeader_company__1LofW .PropertySubHeader_details__O_AkZ .PropertySubHeader_detail__28gmY > img {
          width: 16px;
          height: 16px;
          margin-right: 5px; }
        .PropertySubHeader_header__1duiQ .PropertySubHeader_company__1LofW .PropertySubHeader_details__O_AkZ .PropertySubHeader_detail__28gmY > span {
          letter-spacing: .2px;
          font-size: 12px;
          font-weight: 600; }
  .PropertySubHeader_header__1duiQ .PropertySubHeader_logo__aMsNI {
    max-height: 60px;
    max-width: 120px; }

@media (max-width: 900px) {
  .PropertySubHeader_header__1duiQ {
    height: 150px; }
    .PropertySubHeader_header__1duiQ .PropertySubHeader_company__1LofW .PropertySubHeader_property__2NyyZ {
      font-size: 20px;
      font-weight: 500;
      color: var(--font-color); }
    .PropertySubHeader_header__1duiQ .PropertySubHeader_company__1LofW .PropertySubHeader_details__O_AkZ {
      display: grid;
      grid-template-rows: auto auto; }
    .PropertySubHeader_header__1duiQ .PropertySubHeader_logo__aMsNI {
      max-height: 60px;
      max-width: 100px; } }

.TemplateManager_link__3bmf7 {
  margin-bottom: auto;
  margin-top: auto;
  cursor: pointer;
  text-decoration: underline; }
  .TemplateManager_link__3bmf7:hover {
    color: #3232dd; }

.TemplateManager_menu__2Yrxo a.TemplateManager_option__1aP4s {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  margin-left: 20px; }
  .TemplateManager_menu__2Yrxo a.TemplateManager_option__1aP4s:hover {
    text-decoration: underline !important;
    color: mediumblue; }

.TemplateManager_buttonDisabled__2EFMU {
  cursor: no-drop;
  color: gray;
  margin-left: 20px;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none !important; }
  .TemplateManager_buttonDisabled__2EFMU:hover {
    color: gray; }

.TemplateManager_Active__2ueph {
  color: #43b31f;
  font-size: 14px;
  font-weight: 500; }

.TemplateManager_Inactive__3ObGl {
  color: #FFBF00;
  font-size: 14px;
  font-weight: 500; }

.Footer {
  position: static;
  bottom: 0;
  width: 100%;
  height: calc(100%-100px);
  padding-bottom: 60px;
  background: #0652dd;
  text-align: center;
}

.Footer text {
  color: white;
  font-weight: 300;
  text-decoration: none;
}

.main-page {
  height: 100%;
  background-color: var(--bg-color);
}

.locationlistform {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 50px;
}

.filterpropertylist {
  background: #ffffff;
  border-radius: 20px;
  margin-top: 50px;

  max-width: 800px;
  margin-left: 50px;
  padding: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.searchfields {
  margin-left: 50px;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 20px;
}

.propertylistresults {
  background: #ffffff;
  border-radius: 20px;
  width: -webkit-max-content;
  width: max-content;
  margin-left: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.download-button {
  display: flex;
  height: 34px;
  width: 180px;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none;
  background-color: rgb(239, 239, 239);
  border-radius: 2px;
  border: 1px solid rgb(118, 118, 118);
  font-size: 16px;
}

.download-button:hover {
  background-color: rgb(229, 229, 229);
}

.download-button:active {
  background-color: rgb(244, 244, 244);
}

.button-group { 
  margin-bottom: 25px;
}
.assignuserform {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 50px;
}

.filterproperty {
  max-width: 800px;
  margin-left: 50px;
  padding: 20px;
  border-bottom: 1px solid rgb(194, 194, 194);
}

.searchfields {
  margin-left: 50px;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 20px;
}

.searchfields button {
  margin-top: 0;
}

.assignuser {
  margin-right: 20px;
  display: grid;
  grid-template-columns: 3fr 0.8fr 0.5fr 0.8fr;
  grid-column-gap: 15px;
}

.reassignuser {
  margin-right: 20px;
  display: grid;
  grid-template-columns: 3fr 0.8fr 2fr;
  grid-column-gap: 15px;
}

.propertylist {
  background: #ffffff;
  border-radius: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.userlist {
  background: #dddddd;
  border-radius: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.userlist2 {
  background: #ffffff;
  border-radius: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.table-smaller {
  width: auto;
  margin: 30px;
  float: left;
}

.table-smaller-locations {
  margin: 30px;
  float: left;
}
.table-smaller-users {
  margin: 30px;
  float: left;
}

.table-smaller2 {
  width: auto;
  margin: 30px;
  float: left;
}
.AssignUsers p {
  margin-top: 100px;
}

.navigation {
  width: 100%;
  clear: both;
  background-color: #ffffff;
  text-align: center;
  margin-top: 100px;
}
.col-sm-2 {
  margin-left: 20px;
}

.btn-center {
  width: auto;
  margin: 30px;
  margin-top: 90px;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-arround;
  align-items: flex-start;
}

.gridview .selected,
.gridview tbody .selected {
  background-color: #d3d3d3;
}

.filter_type,
.filter_string {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.filter_type:focus,
.filter_string:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.Portfoliosearch {
  width: calc(100% - 50px);
  height: 140px;

  background: #ffffff;
  border-radius: 20px;

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

  display: grid;
  grid-template-rows: 5px 70px 70px;
}

.Portfoliosearch h3 {
  margin: 20px;
  font-size: 20px;
  text-align: center;
}

.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  height: 42px;
  width: calc(100% - 30px);
  padding: 0px 5px;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;

  width: calc(100% - 50px);
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.propertysearch,
.propertysearchlistbutton {
  width: -webkit-max-content;
  width: max-content;
  height: 42px;
  margin-top: 0;
  font-size: 0.8em;
  color: #eeeeee;
  background: #0652dd;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.propertysearch:hover {
  background: #2166e6;
}
/* .propertysearchlistbutton {
  width: max-content;
  height: 42px;
  margin-top: 0;

  color: #eeeeee;
  background: #0652dd;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} */

.locationlistbutton:hover {
  background: #2166e6;
}

#refreshicon {
  text-align: right;
  margin: 10px;
}

.search-input-area {
  display: grid;
  grid-template-columns: auto 120px 120px;
  grid-gap: 15px;
  gap: 15px;
  margin-right: 20px;
}

@media (max-width: 600px) {
  .search-input-area {
    grid-template-columns: auto;
    margin-left: 20px;
  }

  .Portfoliosearch {
    height: 240px !important;
  }
}




.portfolioBubbleCritical {
  border-radius: 50%;
  cursor: pointer;
  background: #ee5253;
  color: #ffffff;
  text-align: center;
  float: left;
}

.portfolioBubbleCritical:hover {
  transform: scale(1.2, 1.2);
}

.portfolioBubbleWarning {
  border-radius: 50%;
  cursor: pointer;
  background: #ff6b6b;
  color: #ffffff;
  text-align: center;
  float: left;
}
.portfolioBubbleWarning:hover {
  transform: scale(1.2, 1.2);
}

.centerTable {

  width: 100%;

  padding: 10px;

  cursor: pointer;

}



/* #portfoliotable{

  width:100%;

} */

.portfolioBubbleAlert {
  border-radius: 50%;
  cursor: pointer;
  background: #feca57;
  color: #ffffff;
  text-align: center;
  float: left;
}



.portfolioBubbleAlert:hover {
  transform: scale(1.2, 1.2);
}

.portfolioBubbleAdvisory {
  border-radius: 50%;
  cursor: pointer;
  background: #48dbfb;
  color: #ffffff;
  text-align: center;
  float: left;
}

.portfolioBubbleAdvisory:hover {
  transform: scale(1.2, 1.2);
}



.bubbleHolder {

  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 10px;
}
@media (min-width:901px){
  .portfolioBubbleAlert,  .portfolioBubbleWarning, .portfolioBubbleCritical,  .portfolioBubbleAdvisory {
    width: 100px;
    height: 100px;
    margin: 7px;
    padding:8px;
    font-size: 25px;

  }

  .bubbleHolder h6 {
    margin: 0;
    font-size: 18px;
    margin-top: 5px;
  }
  
  .inputarea {
    margin-left: 50px;
    margin-right: 50px;
    display: grid;
    grid-template-columns: auto -webkit-max-content -webkit-max-content;
    grid-template-columns: auto max-content max-content;
    grid-column-gap: 20px;
  }

  .currentthreats{
    margin-left: 10%;
    width: 80%;
  }

  .table-smaller-users{

    /* min-width: 50%; */
    cursor: pointer;
    width:90%;
  
  }

}

@media (max-width:900px){

  .portfolioBubbleAlert,  .portfolioBubbleWarning, .portfolioBubbleCritical,  .portfolioBubbleAdvisory {
    width: 70px;
    height: 70px;
    margin: 3px;
    padding:3px;
  }

  .bubbleHolder h6 {
    margin: 0;
    font-size: 14px;
    margin-top: 5px;
  }

  .inputarea {
    margin-left: 10px;
    margin-right: 10px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 0px;
  }
  .currentthreats{
    margin-left: 5%;
    width: 90%;
  }

  .table-smaller-users{

    /* min-width: 50%; */
    cursor: pointer;
    width:95%;
    margin:5px;
  
  }

  /* #alertdescription{
    display: none;

  } */

}

#messageopened{
  color:#54a0ff;
}
#messagesent{
  color:#8395a7;
}
.sidebar {
  position: relative;
  right: 0;
  width: 100%;
  height: 100px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
  border-left: 1px solid rgba(51, 51, 51, 0.25);
  box-shadow: -1px 0 4px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, auto);
}

.app {
  position: relative;
  height: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.link {
  cursor: pointer;
  color: #3d7e9a;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.progress {
  /* margin-top: 5px; */
  height: 5px;
  padding: 0;
  margin-bottom: 0px;
}

/* .dashboard__map {
  height: 350px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

  min-width: 400px;
  max-width: 100%;
  z-index: 1;
  border-radius: 2px;
  border: 1px solid rgba(51, 51, 51, 0.25);
  box-sizing: border-box;
  overflow: hidden;
} */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}



.alertbox {
  top: 25%;
  left: 35%;
  padding: 20px;
  background: rgb(209, 95, 95);
  border-radius: 5px;
  width: 30%;
  position: fixed;
  z-index: 999999;

}

#exportcsv{
  position: absolute;
  right:80px;
  background-color: #ffffff;
  border-color: #ffffff;
  margin-left: 30px;
  box-shadow: #ffffff;
  z-index: 0;
}

#exporttext{

  margin-left: 10px;
  font-size: 0.8em;
}

.Orgdetails img {
  margin-top: 10px;
  max-width: 200px;
  max-height: 70px;
}

.historical_toggle_button {
  position: absolute;
  width: 75px;
  height: 42px;
  right: 250px;
  top: 10px;
  margin: 0;
  color: #eeeeee;
  /* background: #0652dd; */
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}
.Trackrisks {
  width: 450px;
  height: 421px;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.Trackrisks h3 {
  /* font-size: 20px; */
  border-bottom: 2px solid #c27a25;
  font-weight: bold;
  padding: 4px 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Riskbyproperty {
  width: 450px;
  height: 450px;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Riskbyproperty h3 {
  /* font-size: 20px; */
  border-bottom: 2px solid #c27a25;
  font-weight: bold;
  padding: 10px 30px;
  margin-top: 0;
  margin-bottom: 30px;
}

.Riskmatrix {
  display: grid;
  grid-template-columns: 60px repeat(4, 1fr);
  grid-row-gap: 20px;
  text-align: center;
}
.Riskmatrix h6 {
  font-size: 12px;
  /* font-weight: 800; */
  color: #818080;
  margin: 5px;
}
.Riskmatrix span {
  display: block;
  margin: 5px;

}

.highbox {
  width: 60px;
  height: 32px;
  text-align: center;
  color: #eeeeee;
  background: #ff6b6b;
  cursor: pointer;
  padding: 5px;
  margin:0 auto;}

.highbox a {
  color: #eeeeee;
  text-decoration: none;
}

.highbox:hover a,
.mediumbox:hover a,
.lowbox:hover a {
  color: #eeeeee;
  text-decoration: none;
}

.highbox:hover {
  transform: scale(1.2, 1.2);
}

.mediumbox {
  width: 60px;
  height: 32px;
  text-align: center;
  color: #eeeeee;

  background: #feca57;
  cursor: pointer;
  padding: 5px;
  margin:0 auto;
}

.mediumbox a {
  color: #eeeeee;
  text-decoration: none;
}
.mediumbox:hover {
  transform: scale(1.2, 1.2);
}
.lowbox {
  width: 60px;
  height: 32px;
  text-align: center;
  color: #eeeeee;
  cursor: pointer;
  padding: 5px;
  margin:0 auto;
  background: #1dd1a1;
}

.lowbox a {
  color: #eeeeee;
  text-decoration: none;
}
.lowbox:hover {
  transform: scale(1.2, 1.2);
}

.negligiblebox {
  width: 60px;
  height: 32px;
  text-align: center;
  color: #eeeeee;
  cursor: pointer;
  padding: 5px;
  margin:0 auto;
  background: #c4c4c4;
  cursor: default;

  
}

.negligiblebox a {
  color: #eeeeee;
  text-decoration: none;
  cursor: default;

}

/* .negligiblebox:hover {
  transform: scale(1.2, 1.2);
} */
.App {
  position: relative;
  height: 50%;
  width: 97%;
  left: 20px;
  margin-top: 50px;
  background: #ffffff;
  border-radius: 5px;
}

.Alertdetails {
  /* position: absolute; */
  width: calc(100% - 50px);

  height: 447px;
  /* left: 570px;
  top: 265px; */

  background: #ffffff;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.Alertdetails h3 {
  /* font-size: 20px; */
  font-weight: bold;
  padding: 10px 30px;
  margin-top: 0px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ff0000;
  width: 100%;
}
.dashboard__risk {
  width: 450px;
  /* height: 200px; */

  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/*  */

.balkerne button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.balkerne button:hover,
.balkerne button:focus {
  background: #0053ba;
}

.balkerne button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.balkerne button:active {
  transform: scale(0.99);
}

@media (min-width:901px){

  .main-page {
    min-height: 100%;
  }
  .pagecontent {
    display: grid;
    grid-template-columns: 450px auto;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    margin-top: 40px;
    margin-left: 20px;
  }

  .Orgdetails {
    width: 450px;
    height: 140px;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }
  .current-threats-card {
    width: calc(100% - 50px);
    height: 447px;
  }

  .current-threats-card-content {
    overflow-y: auto;
  }

  #propertyrisktable{
    display: block;
  }
  /* #dashboard_map_main{
    display: block;

  } */
}

@media (max-width:900px){


  h2{
    font-size: 20px;
  }

  h3{
    font-size: 16px;
  }

  .main-page {
    min-height: 50%;
  }
  .pagecontent {
    display: grid;
    grid-template-rows: auto auto auto;
    /* grid-column-gap: 50px; */
    grid-row-gap: 50px;
    margin-top: 40px;
    margin-left: 20px;
  }

  .Orgdetails {
    width: calc(100% - 20px);
    height: 140px;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }

  .current-threats-card {
    width: calc(100% - 20px);
    height: -webkit-max-content;
    height: max-content;  
  }

  #dashboardleft{
    display: none;
  }

  #propertyrisktable{
    display: none;
  }
 
  #dashboard_map_main{
    display: none;

  }

  #viewallbutton{
      display:none;
  }

  #viewmore{
    display:none;
}

  #addressitem{
    display:none;
  }

  #tablealerttype{
    display:none;
  }
  #viewallbutton{
    display: none;
  }
}
.SideNav_nav__1cNGp {
  font-family: var(--font-default);
  min-width: 230px;
  height: 100vh; }

.SideNav_rows__M5g0g {
  position: static;
  position: initial;
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: 230px;
  padding-top: 15px;
  background-color: white;
  border-top: 2px solid #F8F8F8; }
  .SideNav_rows__M5g0g .SideNav_row__3omW0 {
    font-size: 14px;
    font-weight: 500;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
    color: #212121;
    padding: 15px 0 15px 25px;
    cursor: pointer;
    transition: background-color 0.2s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .SideNav_rows__M5g0g .SideNav_row__3omW0:hover {
      background-color: #fbfbfb; }
    .SideNav_rows__M5g0g .SideNav_row__3omW0 .SideNav_icon__1kJpN {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      filter: grayscale(40%);
      transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1); }

.SideNav_highlight__397eI {
  background-color: var(--bg-color); }

.SideNav_fixRows__1HsAN {
  position: fixed !important;
  top: 0;
  left: 0; }

.Riskreportsection {
  width: 950px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  margin-bottom: 20px;
}

.Riskreportsectionheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.alertcontent {
  display: grid;
  grid-template-columns: 50% 40%;
}

.alert-left {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 50px;

  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
}

.alertimage {
  margin-left: 25%;
  height: 117px;
}

.alertsection {
  width: 375px;
}

.alertsection p {
  left: 20px;
  top: 20px;

  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.7);
}

.recommednationsection {
  /* position: absolute; */
  width: 166px;
  /* left: 608px;
  top: 84px; */
}

.notice_text {
  position: relative;
  width: 430px;
  left: 30px;
  margin-bottom: 17px;

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
}

.notice_grid {
  display: grid;
  grid-template-columns: 50px auto;
  margin: 10px 20px;
}

.notice_text p {
  font-size: 15px;
  color: #000000;
}

.notice_text img {
  height: 27px;
  vertical-align: text-top;
}

.insight_text {
  position: relative;
  width: 430px;
  left: 50px;
  height: 132px;
  top: 40px;

  background: #ffffff;
  border: 1px solid #e6d325;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0px 0px;
}
.insight_text p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.insight_text img {
  width: 27px;
  height: 27px;
  margin: 25px 20px;
  vertical-align: text-top;
}

.timeraised {
  text-align: right;
}

.Riskhighlights {
  width: 950px;
  min-height: 500px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Riskhighlightsheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Riskcontent {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.riskmap {
  width: 276px;
  height: 221px;
  margin-top: 25px;
  margin-left: 50px;
}

.riskright {
  margin-left: 50px;
}

.riskdetailsection {
  display: grid;
  grid-template-rows: (2, auto);
  grid-row-gap: 30px;
  row-gap: 30px;
  justify-items: center;
  margin-right: 30px;
}

.risk_insight_text {
  background: #ffffff;
  border: 1px solid #e6d325;
  border-radius: 20px;
  margin-bottom: 50px;
}
.risk_insight_text p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.risk_insight_text img {
  width: 27px;

  margin: 0px 20px;
  vertical-align: text-top;
  align-items: left;
}

.mapkey {
  margin-left: 100px;
  text-align: center;
}

.riskhigh {
  font-size: 20px;
  color: #d63333;
}
.riskmedium {
  font-size: 20px;

  color: #d68533;
}
.risklow {
  font-size: 20px;

  color: #ad5cff;
}
.riskverylow {
  font-size: 20px;

  color: #5c5cff;
}

.historicfloodingkey {
  font-size: 20px;

  color: #005d82;
}

.riskdefinition {
  font-size: 20px;
}

.dashboard__map_risk {
    height: 350px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  
    min-width: 400px;
    z-index: 1;
    border-radius: 2px;
    border: 1px solid rgba(51, 51, 51, 0.25);
    box-sizing: border-box;
    overflow: hidden;
  }
.risk_rating {
  height: 60px;
  border-radius: 5px;
  /* border: 1px solid #c4c4c47a; */
}

.riskratingitems {
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 20px;
  row-gap: 20px;
  justify-items: center;
}

.rating_text {
  text-align: center;
  padding: 0px 15px;
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}

.Incident_incident__ARUDG {
  font-family: var(--font-default);
  width: 950px;
  background: white;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: var(--box-shadow); }
  .Incident_incident__ARUDG .Incident_top__2U25p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; }
    .Incident_incident__ARUDG .Incident_top__2U25p .Incident_left__Mj0nt {
      display: flex;
      grid-gap: 25px;
      gap: 25px; }
    .Incident_incident__ARUDG .Incident_top__2U25p .Incident_right__18CbT {
      display: flex;
      grid-gap: 15px;
      gap: 15px; }
  .Incident_incident__ARUDG .Incident_description__2lWcP {
    font-size: 16px;
    color: var(--font-color); }
  .Incident_incident__ARUDG .Incident_images__17GO8 {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 15px; }
  .Incident_incident__ARUDG .Incident_img__vYZiz {
    height: 50px; }

.TimePassed_timePassed__1Ka_y {
  font-family: var(--font-default);
  font-size: 12px;
  color: var(--font-color-light);
  font-weight: 400; }

.IconButton_button__3ZwoB {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  cursor: pointer; }
  .IconButton_button__3ZwoB > img {
    height: 100%;
    width: auto; }

.FileIcon_large__2Df4E {
  width: 36px; }

.FileIcon_normal__A6WNB {
  width: 24px; }

.FileIcon_small__1QiNM {
  width: 16px; }

.LabeledText_textLine__yn2F6 {
  font-family: var(--font-default);
  display: flex;
  flex-flow: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .LabeledText_textLine__yn2F6 .LabeledText_label__1s_ix {
    font-size: 12px;
    font-weight: 500;
    color: var(--font-color-muted);
    margin-bottom: 5px; }
  .LabeledText_textLine__yn2F6 .LabeledText_text__2GT-g {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    font-size: 15px;
    font-weight: 400;
    color: var(--font-color);
    text-shadow: rgba(0, 0, 0, 0.05) 0 0 1px;
    letter-spacing: .2px; }
  .LabeledText_textLine__yn2F6 .LabeledText_rightAlign__QbPA4 {
    text-align: right;
    justify-content: flex-end; }

.LabeledText_textBox__1dY4C { }

.Crimeriskhighlights {
  width: 950px;
  min-height: 470px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Crimeriskhighlightssheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Crimeriskcontent{
  padding:20px;
  margin-left : 10px;
  display:grid;
  grid-template-columns: 50% 50%;

}

.crimeoverview{
  margin-left:50px;

}

.crimstats{
  display: grid;
  grid-template-columns:repeat(2,auto);
  grid-row-gap:20px;
  row-gap:20px;
  grid-column-gap: 20px;
  column-gap: 20px;
}


#stat_crime:hover{
  transform: scale(1.1);
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.crime_riskright{
  margin-left:50px;
}

.crimeriskdetailsection {
  display: grid;
  grid-template-rows: (2,auto);
  grid-row-gap: 30px;
  row-gap: 30px;

  justify-items: center; 
}

.crime_insight_text {
  background: #ffffff;
  border: 1px solid #e6d325;
  border-radius: 20px;
  margin-bottom:50px;
}

.crime_insight_text p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.crime_insight_text img {
  width: 27px;
  margin: 0px 20px;
  vertical-align: text-top;
  align-items: left;
}
.FileImage_icon__2_6jn {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  width: 40px;
  max-height: 40px; }

.FileImage_image__B1mgc {
  border-radius: 4px;
  border: 1px solid var(--border-color-dark); }

.Mitigation_mitigation__275Tr {
  font-family: var(--font-default);
  width: 950px;
  background: white;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: var(--box-shadow); }
  .Mitigation_mitigation__275Tr .Mitigation_top__1n_nP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; }
    .Mitigation_mitigation__275Tr .Mitigation_top__1n_nP .Mitigation_left__2lZoR {
      display: flex;
      grid-gap: 25px;
      gap: 25px; }
    .Mitigation_mitigation__275Tr .Mitigation_top__1n_nP .Mitigation_right__2AJel {
      display: flex;
      grid-gap: 15px;
      gap: 15px; }
  .Mitigation_mitigation__275Tr .Mitigation_description__1nUBK {
    font-size: 16px;
    color: var(--font-color); }
  .Mitigation_mitigation__275Tr .Mitigation_links__2aJFf {
    margin-top: 15px; }
    .Mitigation_mitigation__275Tr .Mitigation_links__2aJFf .Mitigation_link__1GNA1 {
      color: #427EF1;
      font-size: 12px;
      font-weight: 400; }
  .Mitigation_mitigation__275Tr .Mitigation_images__1pRs7 {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 15px; }
  .Mitigation_mitigation__275Tr .Mitigation_img__21iT2 {
    height: 50px; }

.Rivergauge {
  /* position: relative;
  top: 85px;
  left: 320px; */
  width: 950px;
  height: 1100px;
  border-radius: 20px;
  margin-bottom: 20px;

  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.Rivergauge h3 {
  background: #e5e5e5;
  padding: 10px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Rivergaugeobjects {
  display: grid;
  grid-template-rows: (3, auto);
  justify-items: center;
  grid-row-gap: 50px;
  row-gap: 50px;
}

.gaugetext {
  max-width: 80%;
}

.Gaugemap {
  /* position: relative; */
  width: 400px;
  height: 239px;
  /* top: 30px;
  left: 410px; */

  padding: 5px 5px;

  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}
.Rivergaugechart {
  /* position: relative; */
  top: 50px;
  left: 60px;
  width: 900px;
  height: 239px;
}

.Fireresponse {
  width: 950px;
  min-height: 470px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Fireresponseheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Fireresponsecontent {
  margin-left: 50px;
  margin-right: 50px;

  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 40% 50%;
}

.firestationmap {
  margin-top: 30px;
  margin-left: 10px;
  width: 300px;
  height: 300px;
}
#firedataprogressbar {
  height: 10px;
  width: 420px;
}

.fireriskright {
  margin-left: 50px;
}

.Responsetimeheading {
  text-align: center;
  max-width: 420px;
}

.engaverage {
  width: 100px;
  margin: 0;
  padding: 0;
}

.engaverage p,
.engmaxmimum p {
  margin: 0;
}
.engmaxmimum {
  position: relative;
  left: 370px;
  top: -75px;
  width: 100px;
  text-align: center;
}
.firedetailsection {
  display: grid;
  grid-template-rows: (2, auto);
  grid-row-gap: 30px;
  row-gap: 30px;

  justify-items: center;
}

.fireengineicon {
  max-width: 80px;
}

.fireresponsedetail {
  background: #ffffff;
  border: 1px solid #e6d325;
  border-radius: 20px;
  margin-bottom: 50px;
}
.fireresponsedetail p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.fireresponsedetail img {
  width: 27px;

  margin: 0px 20px;
  vertical-align: text-top;
  align-items: left;
}

.media-item {
  position: relative;

  background: #ffffff;
  border-radius: 10px;
  width: calc(100% - 500px);
  height: 105px;
  left: 320px;
  top: 130px;
  margin-bottom: 30px;

  display: grid;
  grid-template-columns: -webkit-max-content -webkit-max-content auto 0.4fr;
  grid-template-columns: max-content max-content auto 0.4fr;
}

.sourcelogo {
  margin-left: 15px;
  margin-top: 15px;
  grid-row-start: span 2;
}

.icon {
  max-height: 60px;
  max-width: 150px;
  margin-right: 20px;
  width: auto;
  height: auto;
}

.sourcename {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;

  padding: 0px 0px;
  color: #000000;
  vertical-align: top;
}

.handle {
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 10px;
  color: #0041f5;
}
.contentdescription {
  grid-column-start: span 3;
}
.date {
  font-size: 15px;
  text-align: right;
  margin-right: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
  border-radius: 10px;
}

.overlay a {
  color: white;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  left: 45%;
  top: 25px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.media-item:hover .overlay {
  opacity: 0.7;
}

.notice__error {
  position: absolute;
  left: 732px;
  top: 451px;
  font-weight: bold;
}

.addobjects {
  position: absolute;
  right: 150px;
  top: 230px;
}

.dropdown-item {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.dropdown-item:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-item:focus {
  color: #eeeeee;
  text-decoration: none;
  background: #0652dd;
}

.riskReportWrap {
  display: flex;
  background-color: var(--bg-color);
}

.riskPagesWrap {
  margin: 20px 0 0 20px;
  display: flex;
  flex-flow: column;
  grid-gap: 15px;
  gap: 15px;
}

.riskReportContainer {
  display: flex;
  flex-flow: column;
  grid-gap: 15px;
  gap: 15px;
}
.pagination {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr; }
  .pagination__button {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    justify-self: center;
    height: 30px;
    width: 100px;
    font-size: 12px;
    outline: none;
    border: none;
    background-color: #E8E8E8;
    border-radius: 100px; }
    .pagination__button:hover {
      border: 1px solid grey; }

.indices {
  display: flex; }
  .indices__index {
    margin: 0 3px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 12px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #f3f3f3;
    outline: none;
    border: none; }
    .indices__index--selected {
      background-color: #c4d0e8; }
    .indices__index:hover {
      border: 1px solid #cacaca; }

.alert-historical {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  padding: 0 20px;
  margin-bottom: 15px;
  background-color: white;
  border-radius: 22px;
  width: 950px;
  min-height: 200px;
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-column-gap: 30px; }
  .alert-historical__icon {
    width: 150px;
    transform: translateY(10px);
    align-self: center; }
  .alert-historical__title {
    font-weight: 500; }
  .alert-historical__measurements {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.3fr; }

.measurement {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  margin-right: 40px;
  font-size: 16px; }
  .measurement__type {
    font-size: 14px; }
  .measurement__measure {
    font-weight: 500; }
  .measurement ::before {
    background-color: #C4C4C4;
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 20px;
    left: -13px;
    top: 50%;
    transform: translateY(-50%); }

.summary {
  margin-top: 50px;
  width: 950px;
  max-height: 400px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 15px 20px; }

.header {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center; }
  .header__title {
    padding: 10px 10px;
    margin: 0; }
  .header__selected {
    position: relative;
    margin-left: 10px;
    font-size: 14px; }
  .header__filters {
    display: flex;
    flex-flow: row;
    margin-left: auto; }

.historicalertsectioncontent {
  margin: 25px;
  display: grid;
  grid-template-columns: 200px auto; }

.historicalertsummarycontent {
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin: 30px; }

.historicalertnotices {
  display: inline-block;
  text-align: center; }

.historicalertimage {
  max-height: 120px;
  max-width: 170px;
  cursor: pointer; }

.historicalertimage:hover {
  transform: scale(1.1); }

.historiclaertdescription p {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.7); }

.dropdown-button {
  padding-left: 10px; }
  .dropdown-button button {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    justify-self: center;
    height: 30px;
    width: 120px;
    font-size: 14px;
    outline: none;
    border: none;
    background-color: #e8e8e8;
    border-radius: 100px; }
    .dropdown-button button:hover {
      border: 1px solid grey; }

.Weather {
  width: 950px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.Weather h3 {
  background: #e5e5e5;
  padding: 10px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Weatherbjects {
  display: grid;
  grid-template-rows: (2, auto);
  justify-items: center;
  grid-row-gap: 50px;
  row-gap: 50px;
}

.Weathergaugechart {
  width: 1000px;
  height: 239px;
}

.weatherContainer {
  display: flex;
  flex-flow: column;
  grid-gap: 15px;
  gap: 15px;
}

.NewsCard_card__24msH {
  font-family: var(--font-default);
  display: flex;
  justify-content: space-between;
  width: 950px;
  min-height: 165px;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  background-color: white;
  box-shadow: var(--box-shadow);
  margin-bottom: 15px; }
  .NewsCard_card__24msH .NewsCard_content__3ZLiS .NewsCard_top__MOQAm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .NewsCard_card__24msH .NewsCard_content__3ZLiS .NewsCard_top__MOQAm .NewsCard_controls__1MOMH {
      display: flex;
      grid-gap: 5px;
      gap: 5px; }
      .NewsCard_card__24msH .NewsCard_content__3ZLiS .NewsCard_top__MOQAm .NewsCard_controls__1MOMH .NewsCard_external__3jJ9k {
        width: 22px;
        height: 22px;
        cursor: pointer; }
      .NewsCard_card__24msH .NewsCard_content__3ZLiS .NewsCard_top__MOQAm .NewsCard_controls__1MOMH .NewsCard_dropdown__2DzIF {
        background-color: none; }
        .NewsCard_card__24msH .NewsCard_content__3ZLiS .NewsCard_top__MOQAm .NewsCard_controls__1MOMH .NewsCard_dropdown__2DzIF img {
          width: 22px;
          height: 22px; }
        .NewsCard_card__24msH .NewsCard_content__3ZLiS .NewsCard_top__MOQAm .NewsCard_controls__1MOMH .NewsCard_dropdown__2DzIF > button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          background-color: rgba(0, 0, 0, 0);
          border: 0;
          outline: 0; }
  .NewsCard_card__24msH .NewsCard_content__3ZLiS .NewsCard_title__1Yc0J {
    font-size: 18px;
    color: var(--font-color);
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: rgba(0, 0, 0, 0.05) 0 0 1px; }
  .NewsCard_card__24msH .NewsCard_content__3ZLiS .NewsCard_snippet__z54Zw {
    font-size: 14px;
    line-height: 135%;
    color: var(--font-color-muted); }
  .NewsCard_card__24msH .NewsCard_img__1isvV {
    margin-left: 20px;
    object-fit: cover;
    border-radius: 4px;
    width: 315px; }

.Metathefts {
  position: relative;
  width: 950px;
  /* height: 120%; */
  left: 320px;
  top: 85px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Metatheftheading {
  background: #e5e5e5;
  padding: 10px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Metatheftcontainer {
  width: 80%;
  margin-left: 10%;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 40px;
  row-gap: 40px;
}

.Metaltheftpart1 {
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  grid-auto-rows: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
  align-items: center;
}
/* .item {
    border: 1px solid #a1a1a1;
  } */

.Metatheftlist {
  display: inline-grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: 20px;
  grid-row-gap: 10px;
  row-gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.Labeltitle {
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  vertical-align: middle;
}
.Columntitle {
  text-align: center;
}

.Labeltext {
  font-weight: normal;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.covidHighlight {
  width: 950px;
  min-height: 400px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.covidHighlightsheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.covidriskcontent {
  margin: 30px;
  display: grid;
  grid-template-columns: 40% 60%;
}

.covidriskdetailsection {
  display: grid;
  grid-template-rows: (2, auto);
  grid-row-gap: 20px;
  row-gap: 20px;
  justify-items: center;
  margin-right: 30px;
}

.covid_risk_insight_text {
  background: #ffffff;
  border: 1px solid #e6d325;
  border-radius: 20px;
  margin-bottom: 50px;
}
.covid_risk_insight_text p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.riskright {
  text-align: center;
}

.property {
  min-height: 280px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: var(--box-shadow); }
  .property__header {
    background: #e5e5e5;
    padding: 10px 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif; }
    .property__header > .small {
      font-size: 12px; }
  .property__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    row-gap: 30px;
    margin: 40px 60px; }

.detail {
  width: 380px;
  display: grid;
  grid-column-gap: 20px;
  column-gap: 20px;
  grid-template-columns: 130px 230px; }
  .detail__label {
    padding-top: 5px;
    font-size: 18px;
    color: #474747; }
  .detail__container {
    padding: 8px 13px;
    background-color: #F4F4F4;
    border-radius: 5px; }
  .detail__text {
    font-weight: 500;
    font-size: 18px; }
  .detail--rows-2 {
    grid-row: span 2; }
  .detail--rows-3 {
    grid-row: span 3; }

.EPC_epc__12j_r {
  font-family: var(--font-default);
  margin-bottom: 25px; }
  .EPC_epc__12j_r .EPC_title__2J1pE {
    font-size: 16px;
    font-weight: 600; }
  .EPC_epc__12j_r .EPC_card__2GQyH {
    background-color: white;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
    padding: var(--card-padding); }
    .EPC_epc__12j_r .EPC_card__2GQyH .EPC_content__3XV8D {
      display: flex;
      grid-gap: 40px;
      gap: 40px; }

.EPC_breakdowns__3SfjE {
  display: grid;
  grid-template-columns: repeat(2, 465px);
  grid-column-gap: 20px;
  column-gap: 20px; }

.EPC_breakdown__2Cuqs .EPC_outside__1TZnZ {
  display: flex;
  flex-flow: column;
  grid-gap: 15px;
  gap: 15px;
  padding: 15px 25px; }
  .EPC_breakdown__2Cuqs .EPC_outside__1TZnZ .EPC_label__39Svm {
    color: var(--font-color-muted);
    font-size: 12px;
    font-weight: 500;
    margin-left: 25px;
    position: relative; }
    .EPC_breakdown__2Cuqs .EPC_outside__1TZnZ .EPC_label__39Svm::before {
      position: absolute;
      content: "";
      display: block;
      top: 50%;
      left: -25px;
      transform: translateY(-50%);
      width: 20px;
      height: 1px;
      border-radius: 3px;
      background-color: var(--font-color-muted); }

.EPC_breakdown__2Cuqs .EPC_list__2oI9T {
  display: flex;
  flex-flow: column;
  grid-gap: 10px;
  gap: 10px; }

.EPC_rating__6a8wW {
  display: flex; }
  .EPC_rating__6a8wW .EPC_number__vi3d2 {
    font-size: 24px;
    font-weight: 500; }
  .EPC_rating__6a8wW .EPC_letter__grk-e {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: white;
    border-radius: 4px;
    padding: 1px 9px; }

.EPC_breakdownCard__cRVNl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 465px;
  min-height: 55px;
  max-height: 200px;
  background-color: white;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  padding: 0 25px;
  color: var(--font-color);
  --breakdown-font-size: 14px; }

.EPC_recommendation__2H7Fb { }
  .EPC_recommendation__2H7Fb .EPC_left__1H8Uh {
    font-size: var(--breakdown-font-size);
    max-width: 250px; }
  .EPC_recommendation__2H7Fb .EPC_right__3uleK .EPC_text__Bf--t {
    font-weight: 500;
    font-size: 14px; }

.EPC_consumption__29jY3 { }
  .EPC_consumption__29jY3 .EPC_left__1H8Uh {
    font-size: var(--breakdown-font-size); }
  .EPC_consumption__29jY3 .EPC_right__3uleK {
    display: grid;
    justify-content: right;
    justify-items: right;
    grid-template-columns: auto 150px;
    align-items: center;
    grid-row-gap: 25px;
    row-gap: 25px; }
    .EPC_consumption__29jY3 .EPC_right__3uleK .EPC_numbers__1Dpee {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      gap: 10px; }
      .EPC_consumption__29jY3 .EPC_right__3uleK .EPC_numbers__1Dpee .EPC_number__vi3d2 {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px; }
        .EPC_consumption__29jY3 .EPC_right__3uleK .EPC_numbers__1Dpee .EPC_number__vi3d2 .EPC_postfix__1XpRL {
          margin-left: 2px;
          font-size: 12px; }
      .EPC_consumption__29jY3 .EPC_right__3uleK .EPC_numbers__1Dpee .EPC_divider__3-q8S {
        height: 22px;
        width: 1px;
        background-color: var(--font-color-light);
        border-radius: 5px; }
    .EPC_consumption__29jY3 .EPC_right__3uleK .EPC_percentage__1JUvF {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: var(--font-color);
      padding: 5px 10px;
      border-radius: 15px; }

.foodhygiene_foodhygiene__27SKP {
  font-family: var(--font-default);
  width: 950px;
  background: white;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: var(--box-shadow); }
  .foodhygiene_foodhygiene__27SKP .foodhygiene_top__14dgh {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_top__14dgh .foodhygiene_left__1nW9A {
      display: flex;
      grid-gap: 25px;
      gap: 25px; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_top__14dgh .foodhygiene_right__148LQ {
      display: flex;
      grid-gap: 15px;
      gap: 15px; }
  .foodhygiene_foodhygiene__27SKP .foodhygiene_description__3B2jw {
    font-size: 16px;
    color: var(--font-color); }
  .foodhygiene_foodhygiene__27SKP .foodhygiene_images__1t2rd {
    margin-top: 15px; }
  .foodhygiene_foodhygiene__27SKP .foodhygiene_img__Uq3h6 {
    height: 50px; }
  .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq {
    margin-top: 20px;
    min-height: 300px;
    background: #ffffff;
    border-radius: 10px; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygienecontent__3nLWn {
      margin: 20px;
      display: grid;
      grid-template-columns: 50% 50%; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygienedetailsection__1Qffo {
      display: grid;
      grid-template-rows: 2, auto;
      grid-row-gap: 30px;
      row-gap: 30px;
      justify-items: center;
      position: relative; }
      .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygienedetailsection__1Qffo .foodhygiene_button_read__2GqkL {
        color: #fff !important;
        text-transform: uppercase;
        text-decoration: none;
        background: #005AFF;
        padding: 20px;
        border-radius: 5px;
        display: inline-block;
        border: none;
        transition: all 0.4s ease 0s; }
      .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygienedetailsection__1Qffo .foodhygiene_button_read__2GqkL:hover {
        background: #434343;
        letter-spacing: 1px;
        box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_insight_text__2z3u9 {
      border-radius: 20px;
      margin-bottom: 20px;
      padding: 10px 20px;
      width: 400px; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_insight_text__2z3u9 p {
      font-family: Lato;
      font-size: 15px;
      line-height: 20px;
      text-align: justify;
      display: grid;
      grid-template-columns: repeat(2, auto);
      margin: 20px 20px; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_tierright__1oFc1 {
      text-align: center; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt {
      width: 70px;
      border-radius: 5px;
      text-align: center;
      padding: 5px 5px;
      font-weight: bold;
      color: #FFFFFF;
      font-family: Lato;
      font-size: 24px;
      display: inline; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt:nth-of-type(1) {
      background: #2EA949; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt:nth-of-type(2) {
      background: #94C952; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt:nth-of-type(3) {
      background: #FFCC00; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt:nth-of-type(4) {
      background: #FCA144; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt:nth-of-type(5) {
      background: #FE6D59; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt:nth-of-type(6) {
      background: #ff543d; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt:nth-of-type(7) {
      background: #FE6D59; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_text__3KNFt:nth-of-type(8) {
      background: #FCA144; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_rating_insight_text__2Ew30 {
      width: 200px;
      background: #ffffff;
      border: 1px solid #FF5454;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 10px 10px;
      margin-left: 100px; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_foodhygiene_rating_insight_text__2Ew30 p {
      font-family: Lato;
      font-size: 15px;
      line-height: 15px;
      text-align: justify;
      display: grid;
      grid-template-columns: repeat(2, auto);
      margin: 5px 4px; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_star_img__1-qT_ {
      height: 40px;
      width: 42px; }
    .foodhygiene_foodhygiene__27SKP .foodhygiene_foodhygieneHighlight__1Qauq .foodhygiene_rating_img__h3C0x {
      height: 80px;
      width: 100px;
      display: inline;
      margin-left: 150px;
      margin-bottom: 30px;
      overflow: auto;
      vertical-align: middle; }

.foodhygiene_card__3jWGq {
  max-height: 250px;
  overflow: hidden;
  width: 400px;
  float: center;
  vertical-align: middle; }

.foodhygiene_card__3jWGq h1 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 24px;
  font-weight: normal; }

.foodhygiene_card__3jWGq p {
  text-align: center;
  float: center; }

.foodhygiene_card__3jWGq img {
  text-align: center;
  float: center; }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  overflow-y: scroll;
  overflow-x: hidden;
}
.formbackground {
  position: absolute;
  left: 25%;
  width: 600px;
  top: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}

.formcontent {
  margin-left: 10%;
  margin-top: 30px;
  max-width: 70%;
}

.form-group {
  margin-bottom: 30px;
}
.form-group button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-group button:hover,
.form-group button:focus {
  background: #0053ba;
}

.form-group button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.form-group button:active {
  transform: scale(0.99);
}

.form-group-date {
  display: block;
}

.react-datepicker-wrapper {
  border-radius: 5px;
  margin-bottom: 20px;
}

.react-datepicker {
  transform: scale(1.5, 1.5);
  transform-origin: left top;
}
.submitbutton {
  text-align: center;
}

.close_cross_button {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 0;
  border: none;
  background: none;
}

.Portfoliosearch {
  width: calc(100% - 50px);
  height: 140px;

  background: #ffffff;
  border-radius: 20px;

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

  display: grid;
  grid-template-rows: 5px 70px 70px;
}
.Portfoliosearch h3 {
  margin: 20px;
  font-size: 20px;

  text-align: center;
}

.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  height: 42px;
  width: calc(100% - 30px);
  padding: 0px 5px;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;

  width: calc(100% - 50px);
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.propertysearch,
.propertysearchlistbutton {
  width: -webkit-max-content;
  width: max-content;
  height: 42px;
  margin-top: 0;
  font-size: 0.8em;
  color: #eeeeee;
  background: #0652dd;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.propertysearch:hover {
  background: #2166e6;
}
/* .propertysearchlistbutton {
    width: max-content;
    height: 42px;
    margin-top: 0;
  
    color: #eeeeee;
    background: #0652dd;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  } */

.locationlistbutton:hover {
  background: #2166e6;
}

#refreshicon {
  text-align: right;

  margin: 10px;
}

.locationlistform {
  display: grid;
  grid-template-rows: auto;
  /* grid-row-gap: 50px; */
  justify-content: center;
}

.filterpropertylist {
  background: #ffffff;
  margin: 50px 0 0 0;
  border-radius: 20px;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.searchfields {
  margin-left: 50px;
  max-width: 90%;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-column-gap: 20px;
}

.propertylistresults {
  background: #ffffff;
  border-radius: 20px;
  margin: 0;
  width: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  column-gap: 20px;
}

.button {
  border: none;
  height: 34px;
  padding: 1px 10px;
  border-radius: 5px;
  background-color: #417dea;
  color: white;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.button:hover {
  background-color: #0652dd;
}

.button-group {
  display: grid;
  grid-template-columns: repeat(3, -webkit-min-content);
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 20px;
  column-gap: 20px;
}
.main {
  position: absolute;
  left: 0;
  width: 100%;
  max-width: calc(100% - 200px);
  height: calc(100% - 300px);
  z-index: 1;
}

#root {
  height: 100%;
}

.opsapp {
  position: relative;

  height: calc(100% - 70px);
}

.pagelayout {
  height: 100%;
  display: grid;
  grid-template-columns: auto 200px;
}

.boardleft {
  display: grid;
  grid-template-rows: auto 280px;
}

.opsSideBar {
  background: #ffffff;
  width: 200px;
  right: 0;
  /* height: 100%; */
}
.opsBubbleContainer {
  position: absolute;
  top: 100px;
  right: 30px;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 50px;
}
.opsBubbleContainer h6 {
  margin: 0;
  font-size: 18px;
  margin-top: 5px;
}
.bubbleCritical {
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #8b0000;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}

.bubbleWarning {
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #e50000;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}

.alertbox {
  top: 25%;
    left: 35%;
  padding: 20px;
  background: rgb(209, 95, 95);
  border-radius: 5px;
  width: 30%;
  position: fixed;
  z-index: 999999;


}

.bubbleAlert {
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #ffa500;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}

.bubbleNews{
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #78AB46	;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}
.bubbleAll{
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #0652dd;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}

.mediaBar {
  /* position: absolute; */
  width: 100%;
  height: 300px;
  bottom: 0;
  background: #fafafaaf;

  overflow-y: scroll;
  overflow-x: hidden;
}

.mediaBar_title {
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
  padding: 4px 0;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 2px solid rgba(139, 217, 254, 1);
  width: 100%;
}

.media_content {
  display: grid;
  grid-template-columns: 45% 50%;
  grid-column-gap: 5%;
}

.media_item {
  position: relative;
  width: 90%;
  /* height: auto; */
  left: 25px;

  background: #ffffff;
  /* border: 1px solid #8b8b8b; */
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

  display: grid;
  grid-template-columns: 80px auto 10% 15%;
  grid-column-gap: 20px;
}

.media_headline {
  font-size: 15px;
  padding-top: 5px;
}

.media_icon {
  align-content: right;
  max-height: 30px;
  max-width: 100px;
  margin-right: 20px;
  margin: 10px;
}

.media_date {
  text-align: right;
  margin-right: 20px;
  font-size: 12px;
  vertical-align: center;
}

.newsoverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
  border-radius: 10px;
  text-align: center;
}

.newsoverlay a {
  color: white;
  font-size: 20px;
  font-weight: bold;
  left: 45%;
  margin-top: 10px;
  text-decoration: none;
}

.media_item:hover .newsoverlay {
  opacity: 0.7;
}

.remove-button {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  border: 0;
  outline: 0;
  transition: background-color .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-weight: 500;
  font-size: 14px;
  padding: 5px 10px;
}

.remove-button:hover {
  background-color: #ff8a8a;
}

.adduserbackground {
  margin-left: 25%;
  max-width: 800px;
  margin-top: 50px;
  border-radius: 20px;
  background: white;
}
.adduserbackground h2 {
  padding: 20px;
  font-weight: 400;
  background: #e5e5e5a1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.formcontent {
  margin-left: 10%;
  margin-top: 30px;
  max-width: 70%;
  margin-bottom: 50px;
}

.form-group {
  margin-bottom: 30px;
}
.form-group button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-group button:hover,
.form-group button:focus {
  background: #0053ba;
}

.form-group button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.form-group button:active {
  transform: scale(0.99);
}

/* BASIC */

.Login body {
  font-family: "Lato", sans-serif;
  height: 100vh;
}
.logininput {
  position: relative;
  left: 12%;

  display: grid;
  max-width: 70%;
  grid-auto-rows: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  margin: 20px;
}

.logininput a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

.login_logo {
  position: relative;
  width: 250px;
  height: 80px;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

/* TABS */

.logininput h2.inactive {
  color: #cccccc;
}

.logininput h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

.logininput input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  min-height: 50px;
  width: 90%;

  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  border-radius: 5px 5px 5px 5px;
  transition: all 0.3s ease-in-out;
}

.logininput input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

.logininput input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  transform: scale(0.95);
}

.logininput input[type="email"],
.logininput input[type="password"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 90%;
  border: 2px solid #f6f6f6;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
}

.logininput input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

.logininput input[type="text"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */


/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}


#emailinput{

 margin:30px;

}


#userpasswordinput{
  border-bottom: 1px solid rgb(194, 194, 194);


}

#sendverification{
    display: inline-block;
    border: none;
    /* padding: 2rem 2rem; */
    margin-top: 10px;
    text-decoration: none;
    background: #0069ed;
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
  }


  #passwordinstruction{
    text-align: left;
    font-size: 1em;
  }
.changeuserpage {
  background: #ffffff;
  border-radius: 20px;

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

  margin-left: 25%;
  width: 800px;
}

.changeuserpage h2 {
  background: #e5e5e5a1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-weight: 400;
  padding: 20px 10px;
}

.changeuserform {
  margin-left: 10%;
  max-width: 70%;
}

.changeuserdetails {
  margin-bottom: 20px;

  border-bottom: 1px solid rgb(194, 194, 194);

}

#changedetails{
     /* padding: 2rem 2rem; */
      border: none;

     margin-top: 10px;
     text-decoration: none;
     background: #0069ed;
     color: #ffffff;

}
.propertydetails {
  display: grid;
  grid-template-columns: 30% 20% auto;
  grid-column-gap: 20px;
  margin-left: 20px;
  margin-right: 20px;

  border-bottom: 0.5px solid rgba(200, 214, 229, 1);
}

#property-remove-button {
  border: none;
  max-width: 10px;
  float: right;
  margin-top: 0;
  color: darkgrey;
}
.full_name,
.phone,
.email,
.position,
.group,
.name,
.location-search-input,
.address-lookup-select,
.address-lookup-input,
.building_no,
.country {
  display: inline-block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.full_name:focus,
.phone:focus,
.email:focus,
.position:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

#wrapper {
  text-align: center;
  margin-top: 50px;
}
#termscheckbox {
  display: inline-block;
  margin: 20px;
  width: 20px; /*Desired width*/
  height: 20px; /*Desired height*/
}

/* Category */
/* Rectangle 104 */
.GetStarted_underLine__M96n1 {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 6.81%;
  bottom: 93.09%;
  background: #EDF2F9; }

.GetStarted_mainTitle__1PPO1 {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 1.38%;
  bottom: 94.89%;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  color: #283E59; }

.GetStarted_card__3YGoj {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  /* Button / Light */
  background: #FFFFFF;
  /* Border / Light */
  border: 1px solid #EDF2F9;
  box-sizing: border-box;
  /* Even Newer Dropshadow */
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.015);
  border-radius: 8px;
  font-family: var(--font-default);
  display: flex;
  justify-content: space-between;
  min-height: 165px;
  min-width: 700px;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  background-color: white;
  box-shadow: var(--box-shadow);
  margin-bottom: 15px; }
  .GetStarted_card__3YGoj .GetStarted_content__vm3d0 .GetStarted_top__2XmAc {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .GetStarted_card__3YGoj .GetStarted_content__vm3d0 .GetStarted_top__2XmAc .GetStarted_controls__2EaiJ {
      display: flex;
      grid-gap: 5px;
      gap: 5px; }
      .GetStarted_card__3YGoj .GetStarted_content__vm3d0 .GetStarted_top__2XmAc .GetStarted_controls__2EaiJ .GetStarted_external__CE-Ff {
        width: 22px;
        height: 22px;
        cursor: pointer; }
      .GetStarted_card__3YGoj .GetStarted_content__vm3d0 .GetStarted_top__2XmAc .GetStarted_controls__2EaiJ .GetStarted_dropdown__3RmSh {
        background-color: none; }
        .GetStarted_card__3YGoj .GetStarted_content__vm3d0 .GetStarted_top__2XmAc .GetStarted_controls__2EaiJ .GetStarted_dropdown__3RmSh img {
          width: 22px;
          height: 22px; }
        .GetStarted_card__3YGoj .GetStarted_content__vm3d0 .GetStarted_top__2XmAc .GetStarted_controls__2EaiJ .GetStarted_dropdown__3RmSh > button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          background-color: rgba(0, 0, 0, 0);
          border: 0;
          outline: 0; }
  .GetStarted_card__3YGoj .GetStarted_content__vm3d0 .GetStarted_title__L3Icy {
    font-size: 18px;
    color: var(--font-color);
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: rgba(0, 0, 0, 0.05) 0 0 1px; }
  .GetStarted_card__3YGoj .GetStarted_content__vm3d0 .GetStarted_snippet__z0H9V {
    font-size: 14px;
    line-height: 135%;
    color: var(--font-color-muted); }
  .GetStarted_card__3YGoj .GetStarted_img__2Taay {
    margin-left: 20px;
    object-fit: cover;
    border-radius: 4px;
    width: 315px; }

input[type=checkbox] {
  transform: scale(1.5); }

select {
  transform: scale(1.2); }

.CheckBox_container__NmQiG {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 14px;
  gap: 14px; }
  .CheckBox_container__NmQiG .CheckBox_checkbox__3q2SZ {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color-dark);
    background-color: var(--border-color-light);
    border-radius: 100%;
    transition: all .1s; }
    .CheckBox_container__NmQiG .CheckBox_checkbox__3q2SZ > img {
      padding: 4px;
      width: 100%;
      height: auto;
      display: hidden; }
  .CheckBox_container__NmQiG .CheckBox_children__2D73j {
    font-size: 15px;
    color: var(--font-color); }

.CheckBox_wide__e1PoL {
  width: 100%;
  justify-content: space-between; }

.CheckBox_checked__1btCs {
  border: none !important; }
  .CheckBox_checked__1btCs > img {
    display: visible; }

.CheckBox_reverse__2cGeb {
  flex-flow: row-reverse; }

.CheckBox_normal__2iS6- {
  width: 16px;
  height: 16px; }

.CheckBox_large__26aCP {
  width: 20px;
  height: 20px; }

.CheckBox_disabled__2aDiR {
  filter: grayscale(100%);
  border: 0 !important; }

.adduserrows button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  /* font-weight: 600; */
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 10px;
}

.submissionProperty {
  text-align: center;
  margin: 30px;
}

#submittedBulkProperty {
  display: block;
  color: #27ae60;
  font-size: 4em;
}

.submit-navigate button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.submit-navigate button:hover,
.submit-navigate button:focus {
  background: #0053ba;
}

.submit-navigate button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.submit-navigate button:active {
  transform: scale(0.99);
}

.submit-navigate-buttons {
  margin: 20px;
  display: grid;
  grid-row-gap: 10px;
  row-gap: 10px;
}

#manualproperty{
    text-align: right;
    float: bottom-right;
    padding: 30px;
}
.TweetTrainingCard_card__2s6hA {
  font-family: var(--font-default);
  display: flex;
  justify-content: space-between;
  min-height: 165px;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  background-color: white;
  box-shadow: var(--box-shadow);
  margin-bottom: 15px; }
  .TweetTrainingCard_card__2s6hA .TweetTrainingCard_content__1oIWN .TweetTrainingCard_top__3Giyg {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    .TweetTrainingCard_card__2s6hA .TweetTrainingCard_content__1oIWN .TweetTrainingCard_top__3Giyg .TweetTrainingCard_controls__1eEFN {
      display: flex;
      grid-gap: 5px;
      gap: 5px; }
      .TweetTrainingCard_card__2s6hA .TweetTrainingCard_content__1oIWN .TweetTrainingCard_top__3Giyg .TweetTrainingCard_controls__1eEFN .TweetTrainingCard_external__T4PKv {
        width: 22px;
        height: 22px;
        cursor: pointer; }
      .TweetTrainingCard_card__2s6hA .TweetTrainingCard_content__1oIWN .TweetTrainingCard_top__3Giyg .TweetTrainingCard_controls__1eEFN .TweetTrainingCard_dropdown__361pJ {
        background-color: none; }
        .TweetTrainingCard_card__2s6hA .TweetTrainingCard_content__1oIWN .TweetTrainingCard_top__3Giyg .TweetTrainingCard_controls__1eEFN .TweetTrainingCard_dropdown__361pJ img {
          width: 22px;
          height: 22px; }
        .TweetTrainingCard_card__2s6hA .TweetTrainingCard_content__1oIWN .TweetTrainingCard_top__3Giyg .TweetTrainingCard_controls__1eEFN .TweetTrainingCard_dropdown__361pJ > button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          background-color: rgba(0, 0, 0, 0);
          border: 0;
          outline: 0; }
  .TweetTrainingCard_card__2s6hA .TweetTrainingCard_content__1oIWN .TweetTrainingCard_title__AZ5cT {
    font-size: 18px;
    color: var(--font-color);
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: rgba(0, 0, 0, 0.05) 0 0 1px; }
  .TweetTrainingCard_card__2s6hA .TweetTrainingCard_content__1oIWN .TweetTrainingCard_snippet__24ly5 {
    font-size: 14px;
    line-height: 135%;
    color: var(--font-color-muted); }
  .TweetTrainingCard_card__2s6hA .TweetTrainingCard_img__3HLKK {
    margin-left: 20px;
    object-fit: cover;
    border-radius: 4px;
    width: 315px; }

input[type=checkbox] {
  transform: scale(1.5); }

select {
  transform: scale(1.2); }

.Search_container__FLy6c {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  background-color: white;
  height: 40px;
  border-radius: var(--input-border-radius);
  border: solid 1px var(--border-color-light);
  padding-left: 12px; }
  .Search_container__FLy6c .Search_search__2A3RF {
    outline: 0;
    color: #363636;
    font-size: 13px;
    border: 0;
    background-color: transparent; }
    .Search_container__FLy6c .Search_search__2A3RF:-ms-input-placeholder {
      font-size: inherit;
      color: #d4d4d4; }
    .Search_container__FLy6c .Search_search__2A3RF::placeholder {
      font-size: inherit;
      color: #d4d4d4; }
  .Search_container__FLy6c .Search_icon__ZzJde {
    height: 15px; }

.Search_transparent__13kkI {
  background-color: transparent !important;
  border: 0 !important; }

.InputBox_container__sRV73 {
  display: flex;
  flex-flow: column;
  grid-gap: 5px;
  gap: 5px;
  min-width: 200px; }
  .InputBox_container__sRV73 .InputBox_label__3857f {
    color: var(--font-color);
    font-size: 15px;
    font-weight: 400; }
  .InputBox_container__sRV73 .InputBox_sublabel__1-_93 {
    color: var(--font-color-muted);
    font-size: 13px;
    font-weight: 400; }
  .InputBox_container__sRV73 .InputBox_error__1Zq5J {
    font-size: 10px;
    font-weight: 500;
    margin-left: 5px;
    color: #ff746d; }
  .InputBox_container__sRV73 .InputBox_input__rJmry {
    color: #363636;
    background-color: #FFFFFF;
    min-height: 40px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    outline: 0;
    padding: 10px 12px;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--border-color-light);
    font-size: 15px;
    resize: vertical;
    overflow-y: hidden; }
    .InputBox_container__sRV73 .InputBox_input__rJmry:focus {
      border: solid 1px #2684ff; }
    .InputBox_container__sRV73 .InputBox_input__rJmry:-ms-input-placeholder {
      color: #d4d4d4; }
    .InputBox_container__sRV73 .InputBox_input__rJmry::placeholder {
      color: #d4d4d4; }

.AttachedFile_containerAttachedImages__22y1o {
  display: flex;
  flex-direction: row;
  max-width: 50%;
  align-items: center;
  border: 2px solid #9dceeb;
  border-radius: 8px;
  padding: 4px;
  padding-right: 5 px;
  padding-left: 5px;
  margin-right: 15px;
  margin-top: 15px; }
  @media (max-width: 767px) {
    .AttachedFile_containerAttachedImages__22y1o {
      flex-grow: 1;
      max-width: 95%;
      justify-content: space-between;
      margin-right: auto;
      margin-left: auto; } }
  @media (min-width: 767px) {
    .AttachedFile_containerAttachedImages__22y1o {
      display: flex;
      flex-direction: row;
      max-width: 50%;
      align-items: center;
      border: 2px solid #9dceeb;
      border-radius: 8px;
      padding: 4px;
      padding-right: 5px;
      padding-left: 5px;
      margin-right: 15px;
      margin-top: 15px; } }

.AttachedFile_containerSize__W1H9v {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 8px; }

.AttachedFile_close__2_IvC {
  display: flex;
  margin-bottom: auto;
  margin-top: auto;
  font-size: 1.5rem;
  color: #000;
  cursor: pointer; }
  .AttachedFile_close__2_IvC .AttachedFile_closeSpan__1g7UY {
    height: 27px;
    width: 27px; }

.AttachedFile_attachName__1nB7L {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 8px; }

.AttachedFile_attachSize__LKwx8 {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3px; }

@media (max-width: 767px) {
  .DynamicForm_buttonsForm__1KXs- {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 20px;
    width: 100%; } }

@media (min-width: 767px) {
  .DynamicForm_buttonsForm__1KXs- {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%; } }

@media (max-width: 767px) {
  .DynamicForm_buttonCancel__1IMUi {
    margin-top: 10px; } }

@media (min-width: 767px) {
  .DynamicForm_buttonCancel__1IMUi {
    margin-right: auto; } }

@media (max-width: 767px) {
  .DynamicForm_buttonOthers__MQZIZ {
    margin-bottom: 10px; } }

@media (min-width: 767px) {
  .DynamicForm_buttonOthers__MQZIZ {
    margin-left: 10px; } }

.DynamicForm_containerNextPrevious__u3AwI {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px; }

.DynamicForm_containerComments__wMfvM {
  margin: 15px 20px 30px 20px; }

.DynamicForm_buttonAddComment__25sVD {
  margin-top: 20px; }

.DynamicForm_containerForm__OaAbX {
  margin-left: 20px;
  margin-right: 20px; }

.DynamicForm_progressBadge__1p_w1 {
  width: 100%;
  justify-content: end;
  margin-bottom: 30px; }

.AdminTool_dropdownStyle__19l_4 {
  background-color: transparent;
  color: black; }

.AdminTool_progressBadge__3l7Xg {
  width: 100%;
  justify-content: end;
  margin-bottom: 30px; }

.AdminTool_dropdownContainer__3cc5h {
  position: relative;
  display: inline-block; }
  .AdminTool_dropdownContainer__3cc5h:before {
    content: '';
    height: 28px;
    position: absolute;
    right: 7px;
    top: 3px;
    width: 22px;
    background: #fff;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    pointer-events: none;
    display: block; }
  .AdminTool_dropdownContainer__3cc5h:after {
    content: " ";
    position: absolute;
    right: 15px;
    top: 46%;
    margin-top: -3px;
    z-index: 2;
    pointer-events: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6.9px 4px 0 4px;
    border-color: #aaa transparent transparent transparent;
    pointer-events: none; }
  .AdminTool_dropdownContainer__3cc5h select {
    -webkit-appearance: none;
    appearance: none;
    padding: 0 30px 0 10px;
    font-size: 13px;
    color: #363636;
    border: 1px solid #edf2f9;
    border-radius: 3px;
    height: 33px;
    background: #fff;
    margin: 0 5px 5px 0; }

select::-ms-expand {
  display: none; }

.Overlay_overlay__21hJi {
  display: block;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #d6d6d6;
  background-color: rgba(255, 255, 255, 0.65);
  /* Black w/ opacity */ }

.ListIncidents_progress__oKhcB {
  /* margin-top: 5px; */
  height: 5px;
  padding: 0;
  margin-bottom: 0px; }

.Dropzone_dropzone__2A8-M {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  border: 1px dashed var(--border-color-dark); }

.Dropzone_label__3VHOg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0; }

.Dropzone_default__2Q5rp {
  display: none;
  color: transparent; }
  .Dropzone_default__2Q5rp * {
    display: none; }

#orgupload {
  margin-left: 10%;
  margin-right: 10%;

  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 50px;
  row-gap: 50px;
}

.getstartedrisks {
  margin-left: 30px;
  margin-bottom: 30px;
}

.checkboxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 15px;
  row-gap: 15px;
}

.appContainer {
  height: 100%;
  background-color: var(--bg-color);
}

:root {
  --font-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --font-color: #283e59;
  --font-color-muted: #95aac9;
  --font-color-light: #b8b8b8;
  --font-color-link: #0066cb;
  --font-color-link-visited: #006bff;
  --font-size: 15px;
  --focus-color: #2684ff;
  --focus-background: #b9d7ff;
  --bg-color: #f9fbfd;
  --box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --card-padding: 25px;
  --card-border-radius: 8px;
  --card-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --border-color-dark: #d2ddec;
  --border-color-light: #edf2f9;
  --input-border-radius: 6px;
  --label-weight: 400;
  --text-weight: 700;
  --theme-primary: #0652dd;
  --theme-light: #ffffff;
  --button-border-color: #e3ebf6;
  --button-text-color-light: #283e59; }

.link {
  color: #0066cb;
  color: var(--font-color-link); }
  .link:visited {
    color: #006bff;
    color: var(--font-color-link-visited); }

.card {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05); }

.card-title,
.card-header {
  font-weight: 600;
  font-size: 14px;
  min-height: 60px; }

.card-header {
  display: flex;
  align-items: center; }

.card-body {
  font-size: 14px;
  padding: 14px 25px !important; }

.table {
  font-size: 13px;
  margin-bottom: 5px !important; }
  .table thead {
    background-color: #f9fbfd;
    color: #95aac9;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-bottom: 0; }
    .table thead tr th {
      border-top: 0;
      border-bottom: 0; }

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #edf2f9 !important; }

a:hover {
  text-decoration: none !important; }

input[type="search"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font-default);
  outline: 0;
  border: 0;
  width: 100%;
  height: 40px;
  color: #283e59;
  color: var(--font-color);
  font-size: 15px;
  font-size: var(--font-size); }
  input[type="search"]:-ms-input-placeholder {
    color: #95aac9;
    color: var(--font-color-muted);
    font-size: 15px;
    font-size: var(--font-size); }
  input[type="search"]::placeholder {
    color: #95aac9;
    color: var(--font-color-muted);
    font-size: 15px;
    font-size: var(--font-size); }

ul {
  margin: 0 !important; }

li {
  margin: 0 !important; }

.flex-gap-1 {
  grid-gap: 10px;
  gap: 10px; }

.flex-gap-2 {
  grid-gap: 20px;
  gap: 20px; }

.flex-gap-3 {
  grid-gap: 30px;
  gap: 30px; }

.flex-gap-4 {
  grid-gap: 40px;
  gap: 40px; }

.flex-gap-5 {
  grid-gap: 50px;
  gap: 50px; }

.flex-gap-6 {
  grid-gap: 60px;
  gap: 60px; }

.__react_component_tooltip * {
  color: white !important; }

/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 16px 16px 16px 25px;
  padding-bottom: 16px 16px 16px 25px;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  font-weight: 500;
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0;
  padding-left: 0; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #12263f;
  background-color: #ffffff; }
  .table th,
  .table td {
    padding: 16px 16px 16px 25px;
    vertical-align: top;
    border-top: 1px solid #f4f4f4; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f4f4f4; }
  .table tbody + tbody {
    border-top: 2px solid #f4f4f4; }

.table-sm th,
.table-sm td {
  padding: 8px 8px 8px 25px; }

.table-bordered {
  border: 1px solid #f4f4f4; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f4f4f4; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f7fafc66; }

.table-hover tbody tr:hover {
  color: #12263f;
  background-color: #f9fbfd; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f9fbfd; }

.table-hover .table-active:hover {
  background-color: #e6eef7; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #e6eef7; }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #f4f4f4; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #edf2f9;
  border-radius: 8px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 7px;
      border-bottom-left-radius: 7px; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 25px;
  color: #283e59; }

.card-title {
  margin-bottom: 12px; }

.card-subtitle {
  margin-top: -6px;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 25px; }

.card-header {
  padding: 12px 25px;
  margin-bottom: 0;
  color: #283e59;
  background-color: white;
  border-bottom: 1px solid #edf2f9; }
  .card-header:first-child {
    border-radius: 7px 7px 0 0; }

.card-footer {
  padding: 12px 25px;
  color: #283e59;
  background-color: white;
  border-top: 1px solid #edf2f9; }
  .card-footer:last-child {
    border-radius: 0 0 7px 7px; }

.card-header-tabs {
  margin-right: -12.5px;
  margin-bottom: -12px;
  margin-left: -12.5px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -12.5px;
  margin-left: -12.5px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 7px; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px; }

.card-deck .card {
  margin-bottom: 0; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: 0;
    margin-left: 0; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0; } }

.card-group > .card {
  margin-bottom: 0; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 12px; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #283e59;
  color: var(--font-color);
  background-color: #fff;
  border: 1px solid #edf2f9;
  border: 1px solid var(--border-color-light); }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 2px 8px;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: calc(max(0.25em, 3.5px)) 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #f4f4f4; }
  .table .thead-dark th {
    color: inherit;
    border-color: #f4f4f4; } }

:root {
  --font-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --font-color: #283e59;
  --font-color-muted: #95aac9;
  --font-color-light: #b8b8b8;
  --font-color-link: #0066cb;
  --font-color-link-visited: #006bff;
  --font-size: 15px;
  --focus-color: #2684ff;
  --focus-background: #b9d7ff;
  --bg-color: #f9fbfd;
  --box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --card-padding: 25px;
  --card-border-radius: 8px;
  --card-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  --border-color-dark: #d2ddec;
  --border-color-light: #edf2f9;
  --input-border-radius: 6px;
  --label-weight: 400;
  --text-weight: 700;
  --theme-primary: #0652dd;
  --theme-light: #ffffff;
  --button-border-color: #e3ebf6;
  --button-text-color-light: #283e59; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 6px 0;
  color: #283e59;
  color: var(--font-color);
  font-weight: 500; }

h1 {
  font-size: 26px; }

h2 {
  font-size: 20px; }

h3 {
  font-size: 17px; }

h4 {
  font-size: 15px; }

h5 {
  font-size: 13px; }

h6 {
  font-size: 10px; }

p {
  color: #283e59;
  color: var(--font-color);
  font-size: 15px;
  font-weight: 400; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: var(--font-default);
  font-size: 12pt;
  line-height: 1.2;
  scroll-behavior: smooth;
  min-height: 100%;
  background-color: #f9fbfd;
  background-color: var(--bg-color); }

#root {
  min-height: 100%; }

