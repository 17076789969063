.app {
  position: relative;
  height: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.link {
  cursor: pointer;
  color: #3d7e9a;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.progress {
  /* margin-top: 5px; */
  height: 5px;
  padding: 0;
  margin-bottom: 0px;
}
