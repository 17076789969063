.AssignUsersLastStep p {
  margin-top: 100px;
}

.navigation {
  width: 100%;
  clear: both;
  background-color: #ffffff;
  text-align: center;
  margin-top: 100px;
}
.btn-lg {
  clear: both;
  background-color: #ffffff;
  text-align: center;
  margin-top: 100px;
}

.col-sm-2 {
  margin-left: 30px;
}
.Userlaststep {
  margin-top: 50px;

  margin-left: 31%;
  display: inline-grid;
  grid-template-rows: auto auto;
  text-align: center;
}

.Userlaststep button {
  display: inline-block;
  border: none;
  padding: 1rem 4rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: rgb(8, 143, 8);
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.Userlaststep button:hover,
.Userlaststep button:focus {
  background: rgba(8, 143, 8, 0.692);
}

.Userlaststep button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}
