@import url("https://fonts.googleapis.com/css?family=Lato");
@import './scss/balkerne-theme';
@import '../node_modules/bootstrap/scss/bootstrap';
@import './scss/variables';
@import './scss/typography.scss';

body {
  margin: 0;
  font-family: var(--font-default);
  font-size: 12pt;
  line-height: 1.2;
  scroll-behavior: smooth;
  min-height: 100%;
  background-color: var(--bg-color);
}

#root {
  min-height: 100%;
}
