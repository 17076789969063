.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  width: 40px;
  max-height: 40px;
}

.image {
  border-radius: 4px;
  border: 1px solid var(--border-color-dark);
}