.map {
  height: 100%;
  width: 100%;
  border-radius: var(--card-border-radius);
}

.map_container {
  height: 400px;
  width: 100%;
  margin: 6px 0;
}

.input_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 6px;
}