.Rivergauge {
  /* position: relative;
  top: 85px;
  left: 320px; */
  width: 950px;
  height: 1100px;
  border-radius: 20px;
  margin-bottom: 20px;

  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.Rivergauge h3 {
  background: #e5e5e5;
  padding: 10px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Rivergaugeobjects {
  display: grid;
  grid-template-rows: (3, auto);
  justify-items: center;
  row-gap: 50px;
}

.gaugetext {
  max-width: 80%;
}

.Gaugemap {
  /* position: relative; */
  width: 400px;
  height: 239px;
  /* top: 30px;
  left: 410px; */

  padding: 5px 5px;

  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}
.Rivergaugechart {
  /* position: relative; */
  top: 50px;
  left: 60px;
  width: 900px;
  height: 239px;
}
