.Fireresponse {
  width: 950px;
  min-height: 470px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Fireresponseheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Fireresponsecontent {
  margin-left: 50px;
  margin-right: 50px;

  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 40% 50%;
}

.firestationmap {
  margin-top: 30px;
  margin-left: 10px;
  width: 300px;
  height: 300px;
}
#firedataprogressbar {
  height: 10px;
  width: 420px;
}

.fireriskright {
  margin-left: 50px;
}

.Responsetimeheading {
  text-align: center;
  max-width: 420px;
}

.engaverage {
  width: 100px;
  margin: 0;
  padding: 0;
}

.engaverage p,
.engmaxmimum p {
  margin: 0;
}
.engmaxmimum {
  position: relative;
  left: 370px;
  top: -75px;
  width: 100px;
  text-align: center;
}
.firedetailsection {
  display: grid;
  grid-template-rows: (2, auto);
  row-gap: 30px;

  justify-items: center;
}

.fireengineicon {
  max-width: 80px;
}

.fireresponsedetail {
  background: #ffffff;
  border: 1px solid #e6d325;
  border-radius: 20px;
  margin-bottom: 50px;
}
.fireresponsedetail p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.fireresponsedetail img {
  width: 27px;

  margin: 0px 20px;
  vertical-align: text-top;
  align-items: left;
}
