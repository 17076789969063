.foodhygiene {
  font-family: var(--font-default);
  width: 950px;
  background: white;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: var(--box-shadow);

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .left {
      display: flex;
      gap: 25px;
    }

    .right {
      display: flex;
      gap: 15px;
    }
  }

  .description {
    font-size: 16px;
    color: var(--font-color);
  }

  .images {
    margin-top: 15px;
  }

  .img {
    height: 50px;
  }
  .foodhygieneHighlight {
      margin-top:20px;
      min-height: 300px;
      background: #ffffff;
      border-radius: 10px;
    
    .foodhygienecontent{
      margin:20px;
      display:grid;
      grid-template-columns: 50% 50%;
      
    }
    
    .foodhygienedetailsection {
      display: grid;
      grid-template-rows: (2,auto);
      row-gap: 30px;
      justify-items: center; 
      // margin-left: 50px;
      position: relative;

      .button_read {
        color: #fff !important;
        text-transform: uppercase;
        text-decoration: none;
        background: #005AFF;
        padding: 20px;
        border-radius: 5px;
        display: inline-block;
        border: none;
        transition: all 0.4s ease 0s;
        }
    
      .button_read:hover {
        background: #434343;
        letter-spacing: 1px;
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
        box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
        transition: all 0.4s ease 0s;
        }
    }
    
    .foodhygiene_insight_text {
      // background: #F5F5F5;
      // background: #FFFFFF;
      // border: 1px solid #e6d325;
      border-radius: 20px;
      margin-bottom:20px;
      padding: 10px 20px;
      width: 400px;
    }
    .foodhygiene_insight_text p {
      font-family: Lato;
      font-size: 15px;
      line-height: 20px;
      text-align: justify;
      display: grid;
      grid-template-columns: repeat(2, auto);
      margin: 20px 20px;
    }
    
    .tierright{
      text-align: center;
    
    }
    .foodhygiene_text {
      width: 70px;
      // height: 70px;
      border-radius: 5px;
      text-align: center;
      padding: 5px 5px;
      font-weight: bold;
      color: #FFFFFF;
      font-family: Lato;
      font-size: 24px;
      display: inline;
    }
    .foodhygiene_text:nth-of-type(1) {
      background: #2EA949;
    }
    .foodhygiene_text:nth-of-type(2) {
      background: #94C952;
    } 
    .foodhygiene_text:nth-of-type(3) {
      background: #FFCC00;
    } 
    .foodhygiene_text:nth-of-type(4) {
      background: #FCA144;
    } 
    .foodhygiene_text:nth-of-type(5) {
      background: #FE6D59;
    } 
    .foodhygiene_text:nth-of-type(6) {
      background: #ff543d;
    } 
    .foodhygiene_text:nth-of-type(7) {
      background: #FE6D59;
    } 
    .foodhygiene_text:nth-of-type(8) {
      background: #FCA144;
    } 

    .foodhygiene_rating_insight_text {
      width: 200px;
      background: #ffffff;
      border: 1px solid #FF5454;
      border-radius: 10px;
      margin-bottom:10px;
      padding: 10px 10px;
      margin-left: 100px;
    }
    .foodhygiene_rating_insight_text p {
      font-family: Lato;
      font-size: 15px;
      line-height: 15px;
      text-align: justify;
      display: grid;
      grid-template-columns: repeat(2, auto);
      margin: 5px 4px;
    }

    .star_img {
      height: 40px;
      width: 42px;
    }

    .rating_img{
      height: 80px;
      width: 100px;
      display: inline;
      margin-left: 150px;
      margin-bottom:30px;
      overflow: auto;
      vertical-align:middle;
    }

  }
}

.card {
  // margin: 1rem;
  // background: white;
  // box-shadow: 2px 4px 25px rgba(133, 133, 133, 0.1);
  max-height: 250px;
  // padding: 10px 0;
  // border-radius: 12px;
  // margin: 5px 4px;
  overflow: hidden;
  width: 400px;
  // row-gap: 20px;
  float:center;
  vertical-align:middle;
}

.card h1 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
}

.card p {
  text-align: center;
  float:center;
}
.card img {
  text-align: center;
  float:center;
}
// .card:nth-child(2n) h1 {
//   background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
// }
