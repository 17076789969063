.epc {
  font-family: var(--font-default);
  margin-bottom: 25px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .card {
    background-color: white;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
    padding: var(--card-padding);
    
    .content {
      display: flex;
      gap: 40px;
    }
  }
}

.breakdowns {
  display: grid;
  grid-template-columns: repeat(2, 465px);
  column-gap: 20px;
}

.breakdown {

  .outside {
    display: flex;
    flex-flow: column;
    gap: 15px;
    padding: 15px 25px;

    .label {
      color: var(--font-color-muted);
      font-size: 12px;
      font-weight: 500;
      margin-left: 25px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        display: block;
        top: 50%;
        left: -25px;
        transform: translateY(-50%);
        width: 20px;
        height: 1px;
        border-radius: 3px;
        background-color: var(--font-color-muted);
      }
    }
  }

  .list {
    display: flex;
    flex-flow: column;
    gap: 10px;
  }
}

.rating {
  display: flex;

  .number {
    font-size: 24px;
    font-weight: 500;
  }

  .letter {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: white;
    border-radius: 4px;
    padding: 1px 9px;
  }
}

// General breakdown card class
.breakdownCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 465px;
  min-height: 55px;
  max-height: 200px;
  background-color: white;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  padding: 0 25px;
  color: var(--font-color);

  --breakdown-font-size: 14px;
}

// Specific breakdown card
.recommendation {
  composes: breakdownCard;

  .left {
    font-size: var(--breakdown-font-size);
    max-width: 250px;
  }

  .right {

    .text {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

// Specific breakdown card
.consumption {
  composes: breakdownCard;

  .left {
    font-size: var(--breakdown-font-size);
  }

  .right {
    display: grid;
    justify-content: right;
    justify-items: right;
    grid-template-columns: auto 150px;
    align-items: center;
    row-gap: 25px;
    
    .numbers {
      display: flex;
      align-items: center;
      gap: 10px;

      .number {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;

        .postfix {
          margin-left: 2px;
          font-size: 12px;
        }
      }

      .divider {
        height: 22px;
        width: 1px;
        background-color: var(--font-color-light);
        border-radius: 5px;
      }
    }
    
    .percentage {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: var(--font-color);
      padding: 5px 10px;
      border-radius: 15px;
    }
  }
}