.map {
  height: 100%;
}

.info_window {
  font-family: var(--font-default);
  font-size: 14px;
  font-weight: 14px;
  line-height: 1.4;
  color: rgba(51, 51, 51, 1);
}

.info_window > *:first-child {
  margin-top: 0;
}

.info_window > *:last-child {
  margin-bottom: 0;
}

.info_window__heading {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.info_window__subheading {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
}

.info_window__text--no-space {
  margin-top: 0;
}

.info_window .dashboardButton {
  width: 100%;
  background-color: rgb(6, 82, 221);
  color: white;
  border: 0;
  outline: 0;
  border-radius: 19px;
  padding: 5px 0;
}
