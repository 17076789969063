.pagination {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;

  &__button {
    user-select: none;
    justify-self: center;
    height: 30px;
    width: 100px;
    font-size: 12px; 
    outline: none;
    border: none;
    background-color: #E8E8E8;
    border-radius: 100px;
  
    &:hover {
      border: 1px solid grey;
    }
  }
}

.indices {
  display: flex;

  &__index {
    margin: 0 3px;
    user-select: none;
    font-size: 12px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #f3f3f3;;
    outline: none;
    border: none;

    &--selected {
      background-color: #c4d0e8;
    }

    &:hover {
      border: 1px solid #cacaca;
    }
  }
}