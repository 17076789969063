.header {
  font-family: var(--font-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 27px;
  height: 85px;
  width: 100%;
  background-color: white;

  .company {
    display: flex;
    flex-flow: column;
    gap: 10px;
    
    .property {
      font-size: 22px;
      font-weight: 600;
      color: var(--font-color);
    }

    .details {
      display: flex;
      gap: 20px;
      
      .detail {
        display: flex;
        align-items: center;
        color: var(--font-color-muted);

        > img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        > span {
          letter-spacing: .2px;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  .logo {
    max-height: 60px;
    max-width: 120px;
  }
}

@media (max-width:900px){


  .header{

    height: 150px;
    .company{
      .property {
        font-size: 20px;
        font-weight: 500;
        color: var(--font-color);
      }
      .details{
        display:grid;
        grid-template-rows: auto auto;
      }
    }
    .logo {
      max-height: 60px;
      max-width: 100px;
    }
  }


}