.container {
  padding: 0 35px 55px 35px;
  width: 100%;
}

.centerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.center {
  margin-top: 20px;
  width: 950px;
  max-width: 950px;
}

.centerResponsive {
  margin-top: 20px;
  width: 100%;
  max-width: 950px;
}