


.portfolioBubbleCritical {
  border-radius: 50%;
  cursor: pointer;
  background: #ee5253;
  color: #ffffff;
  text-align: center;
  float: left;
}

.portfolioBubbleCritical:hover {
  transform: scale(1.2, 1.2);
}

.portfolioBubbleWarning {
  border-radius: 50%;
  cursor: pointer;
  background: #ff6b6b;
  color: #ffffff;
  text-align: center;
  float: left;
}
.portfolioBubbleWarning:hover {
  transform: scale(1.2, 1.2);
}

.centerTable {

  width: 100%;

  padding: 10px;

  cursor: pointer;

}



/* #portfoliotable{

  width:100%;

} */

.portfolioBubbleAlert {
  border-radius: 50%;
  cursor: pointer;
  background: #feca57;
  color: #ffffff;
  text-align: center;
  float: left;
}



.portfolioBubbleAlert:hover {
  transform: scale(1.2, 1.2);
}

.portfolioBubbleAdvisory {
  border-radius: 50%;
  cursor: pointer;
  background: #48dbfb;
  color: #ffffff;
  text-align: center;
  float: left;
}

.portfolioBubbleAdvisory:hover {
  transform: scale(1.2, 1.2);
}



.bubbleHolder {

  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 10px;
}
@media (min-width:901px){
  .portfolioBubbleAlert,  .portfolioBubbleWarning, .portfolioBubbleCritical,  .portfolioBubbleAdvisory {
    width: 100px;
    height: 100px;
    margin: 7px;
    padding:8px;
    font-size: 25px;

  }

  .bubbleHolder h6 {
    margin: 0;
    font-size: 18px;
    margin-top: 5px;
  }
  
  .inputarea {
    margin-left: 50px;
    margin-right: 50px;
    display: grid;
    grid-template-columns: auto max-content max-content;
    grid-column-gap: 20px;
  }

  .currentthreats{
    margin-left: 10%;
    width: 80%;
  }

  .table-smaller-users{

    /* min-width: 50%; */
    cursor: pointer;
    width:90%;
  
  }

}

@media (max-width:900px){

  .portfolioBubbleAlert,  .portfolioBubbleWarning, .portfolioBubbleCritical,  .portfolioBubbleAdvisory {
    width: 70px;
    height: 70px;
    margin: 3px;
    padding:3px;
  }

  .bubbleHolder h6 {
    margin: 0;
    font-size: 14px;
    margin-top: 5px;
  }

  .inputarea {
    margin-left: 10px;
    margin-right: 10px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 0px;
  }
  .currentthreats{
    margin-left: 5%;
    width: 90%;
  }

  .table-smaller-users{

    /* min-width: 50%; */
    cursor: pointer;
    width:95%;
    margin:5px;
  
  }

  /* #alertdescription{
    display: none;

  } */

}

#messageopened{
  color:#54a0ff;
}
#messagesent{
  color:#8395a7;
}