.container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 14px;

  .checkbox {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color-dark);
    background-color: var(--border-color-light);
    border-radius: 100%;
    transition: all .1s;
    
    > img {
      padding: 4px;
      width: 100%;
      height: auto;
      display: hidden;
    }
  }

  .children {
    font-size: 15px;
    color: var(--font-color);
  }
}

.wide {
  width: 100%;
  justify-content: space-between;
}

.checked {
  border: none !important;

  > img {
    display: visible;
  }
}

.reverse {
  flex-flow: row-reverse;
}

.normal {
  width: 16px;
  height: 16px;
}

.large {
  width: 20px;
  height: 20px;
}

.disabled {
  filter: grayscale(100%);
  border: 0 !important;
}