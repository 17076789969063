.covidHighlight {
  width: 950px;
  min-height: 400px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.covidHighlightsheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.covidriskcontent {
  margin: 30px;
  display: grid;
  grid-template-columns: 40% 60%;
}

.covidriskdetailsection {
  display: grid;
  grid-template-rows: (2, auto);
  row-gap: 20px;
  justify-items: center;
  margin-right: 30px;
}

.covid_risk_insight_text {
  background: #ffffff;
  border: 1px solid #e6d325;
  border-radius: 20px;
  margin-bottom: 50px;
}
.covid_risk_insight_text p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.riskright {
  text-align: center;
}
