.addlocationform {
  background: #ffffff;
  border-radius: 20px;
  width: 80%;
  min-width: 900px;
  margin-top: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  margin-left: 10%;
}

.addlocationheading {
  background: #e5e5e5a1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.addlocationheading h2 {
  font-weight: 400;
  padding: 0px 5px;
  margin-left: 20px;
}

.addlocationheading p {
  text-align: right;
  margin-right: 20px;
}

.addlocationform button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.addlocationform button:hover,
.addlocationform button:focus {
  background: #0053ba;
}

.addlocationform button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.addlocationform button:active {
  transform: scale(0.99);
}
