.dropdownStyle{
    background-color: transparent;
    color: black;
}

.progressBadge{
    width: 100%;
    justify-content: end;
    margin-bottom: 30px;
}

$height: 33;
.dropdownContainer {
  position: relative;
  display: inline-block;
  &:before {
    content: '';
    height: ($height - 5) + px;
    position: absolute;
    right: 7px;
    top: 3px;
    width: 22px;

    //background: -webkit-linear-gradient(#fff, #f0f0f0);
    //background: -moz-linear-gradient(#fff, #f0f0f0);
    //background: linear-gradient(#f5f5f5, #e0e0e0);
    background: #fff; //for Firefox in Android

    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    pointer-events: none;
    display: block;
  }
  &:after {
    content: " ";
    position: absolute;
    right: 15px;
    top: 46%;
    margin-top: -3px;
    z-index: 2;
    pointer-events: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6.9px 4px 0 4px;
    border-color: #aaa transparent transparent transparent;
    pointer-events: none;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 30px 0 10px;
    font-size: 13px;
    color: #363636;
    border: 1px solid #edf2f9;
    border-radius: 3px;
    height: $height + px;
    //box-shadow: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
    background: #fff;

    //min-width: 200px;
    margin: 0 5px 5px 0;
  }
}
//fix for ie 10 later
select::-ms-expand {
  display: none;
}
