.Footer {
  position: static;
  bottom: 0;
  width: 100%;
  height: calc(100%-100px);
  padding-bottom: 60px;
  background: #0652dd;
  text-align: center;
}

.Footer text {
  color: white;
  font-weight: 300;
  text-decoration: none;
}
