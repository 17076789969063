.option__category {
  padding: 0px 10px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.25);
  line-height: 1.4;
  color: rgba(51, 51, 51, 1);
  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.option__category--dashboard {
  border-top: none !important;
  border-bottom: none !important;
  border-radius: 5px;
}

.option__category > summary + * {
  margin-left: 20px;
}

.option__category > summary {
  display: grid;
  grid-template-columns: 25px auto 60px;
}

.option__category p {
  margin: 10px;
  vertical-align: middle;
}
.option__heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.option__heading--single {
  cursor: auto !important;
  display: grid;
  grid-template-columns: auto 60px;
}

.option__heading:active,
.option__heading:focus {
  outline: none;
}

.option__heading--empty {
  list-style: none;
  /* display: grid;
  grid-template-columns: auto 60px; */
}

.option__list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.option__item {
  margin-bottom: 0.5em;

  font-size: 14px;

  display: grid;
  grid-template-columns: auto 60px;
}

.option__item:last-child {
  margin-bottom: 0;
}

.option__icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

.option__icon--box {
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 2px;
}
