.risk_rating {
  height: 60px;
  border-radius: 5px;
  /* border: 1px solid #c4c4c47a; */
}

.riskratingitems {
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 20px;
  justify-items: center;
}

.rating_text {
  text-align: center;
  padding: 0px 15px;
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}
