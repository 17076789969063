.user {
  display: flex;
  flex-flow: column;
  gap: 15px;

  .row_1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .row_2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}
