.header {
  display: flex;
  align-items: center;
  min-height: 80px;
  width: 100%;
  border-bottom: 1px solid var(--border-color-light);
  margin-bottom: 25px;
}

.title {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  flex-grow: 1;
}