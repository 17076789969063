.adduserpage {
  background: #ffffff;
  border-radius: 20px;
  margin-top: 50px;
  min-width: 900px;

  margin-left: 5%;
  width: 90%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.addusersheading {
  background: #e5e5e5a1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.addusersheading h2 {
  font-weight: 400;
  padding: 0px 5px;
  margin-left: 20px;
}

.addusersheading p {
  text-align: right;
  margin-right: 30px;
}

.adduserbutton {
  margin-left: 40px;
}

.removeuserbutton {
  margin-left: 40px;
}

.adduserpage button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.adduserpage button:hover,
.adduserpage button:focus {
  background: #0053ba;
}

.adduserpage button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.adduserpage button:active {
  transform: scale(0.99);
}
