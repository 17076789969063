.buttonsForm{
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 20px;
        width: 100%;
    }
        
     @media (min-width: 767px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 20px;
        width: 100%;
    }
}

.buttonCancel{
    @media (max-width: 767px) {
        margin-top: 10px;
    }
     @media (min-width: 767px) {
        margin-right: auto;
    }
}

.buttonOthers{
    @media (max-width: 767px) {
        margin-bottom: 10px;
    }
      @media (min-width: 767px) {
         margin-left: 10px;
     }
}

.containerNextPrevious{
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 30px;
}

.containerComments{
    margin: 15px 20px 30px 20px; 
}

.buttonAddComment{
    margin-top: 20px;
}

.containerForm{
    margin-left: 20px;
    margin-right: 20px;
}

.progressBadge{
    width: 100%;
    justify-content: end;
    margin-bottom: 30px;
}