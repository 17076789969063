.Crimeriskhighlights {
  width: 950px;
  min-height: 470px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Crimeriskhighlightssheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Crimeriskcontent{
  padding:20px;
  margin-left : 10px;
  display:grid;
  grid-template-columns: 50% 50%;

}

.crimeoverview{
  margin-left:50px;

}

.crimstats{
  display: grid;
  grid-template-columns:repeat(2,auto);
  row-gap:20px;
  column-gap: 20px;
}


#stat_crime:hover{
  transform: scale(1.1);
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.crime_riskright{
  margin-left:50px;
}

.crimeriskdetailsection {
  display: grid;
  grid-template-rows: (2,auto);
  row-gap: 30px;

  justify-items: center; 
}

.crime_insight_text {
  background: #ffffff;
  border: 1px solid #e6d325;
  border-radius: 20px;
  margin-bottom:50px;
}

.crime_insight_text p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.crime_insight_text img {
  width: 27px;
  margin: 0px 20px;
  vertical-align: text-top;
  align-items: left;
}