.alert-historical {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  padding: 0 20px;
  margin-bottom: 15px;
  background-color: white;
  border-radius: 22px;
  width: 950px;
  min-height: 200px;
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-column-gap: 30px;

  &__icon {
    width: 150px;
    transform: translateY(10px);
    align-self: center;
  }

  &__title {
    font-weight: 500;
  }

  &__measurements {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.3fr;
  }
}

.measurement {
  display: grid;
  grid-template-columns: 1fr;

  position: relative;
  margin-right: 40px;
  font-size: 16px;

  &__type {
    font-size: 14px;
  }

  &__measure {
    font-weight: 500;
  }

  ::before {
    background-color: #C4C4C4 ;
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 20px;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
  }
}