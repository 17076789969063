.button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;
  cursor: pointer;

  > img {
    height: 100%;
    width: auto;
  }
}