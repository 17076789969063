.overlay {
    display: block;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(214, 214, 214);
    background-color: rgba(255, 255, 255, 0.65); /* Black w/ opacity */
}