.adduserbackground {
  margin-left: 25%;
  max-width: 800px;
  margin-top: 50px;
  border-radius: 20px;
  background: white;
}
.adduserbackground h2 {
  padding: 20px;
  font-weight: 400;
  background: #e5e5e5a1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.formcontent {
  margin-left: 10%;
  margin-top: 30px;
  max-width: 70%;
  margin-bottom: 50px;
}

.form-group {
  margin-bottom: 30px;
}
.form-group button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-group button:hover,
.form-group button:focus {
  background: #0053ba;
}

.form-group button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.form-group button:active {
  transform: scale(0.99);
}
