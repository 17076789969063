.Weather {
  width: 950px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.Weather h3 {
  background: #e5e5e5;
  padding: 10px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Weatherbjects {
  display: grid;
  grid-template-rows: (2, auto);
  justify-items: center;
  row-gap: 50px;
}

.Weathergaugechart {
  width: 1000px;
  height: 239px;
}

.weatherContainer {
  display: flex;
  flex-flow: column;
  gap: 15px;
}
