.submissionProperty {
  text-align: center;
  margin: 30px;
}

#submittedBulkProperty {
  display: block;
  color: #27ae60;
  font-size: 4em;
}

.submit-navigate button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.submit-navigate button:hover,
.submit-navigate button:focus {
  background: #0053ba;
}

.submit-navigate button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.submit-navigate button:active {
  transform: scale(0.99);
}

.submit-navigate-buttons {
  margin: 20px;
  display: grid;
  row-gap: 10px;
}
