.Metathefts {
  position: relative;
  width: 950px;
  /* height: 120%; */
  left: 320px;
  top: 85px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Metatheftheading {
  background: #e5e5e5;
  padding: 10px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Metatheftcontainer {
  width: 80%;
  margin-left: 10%;
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 40px;
}

.Metaltheftpart1 {
  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  grid-auto-rows: 20px;
  row-gap: 10px;
  align-items: center;
}
/* .item {
    border: 1px solid #a1a1a1;
  } */

.Metatheftlist {
  display: inline-grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: 20px;
  row-gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.Labeltitle {
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  vertical-align: middle;
}
.Columntitle {
  text-align: center;
}

.Labeltext {
  font-weight: normal;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}
