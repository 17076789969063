/* Category */
/* Rectangle 104 */

.underLine{
  position: absolute;
  left: 0%;
  right: 0%;
  top: 6.81%;
  bottom: 93.09%;
  background: #EDF2F9;
  }
  
  .mainTitle {
      position: absolute;
  left: 0%;
  right: 0%;
  top: 1.38%;
  bottom: 94.89%;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  color: #283E59;
    }
  
    
  
    .card {
      position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      
      /* Button / Light */
      
      background: #FFFFFF;
      /* Border / Light */
      
      border: 1px solid #EDF2F9;
      box-sizing: border-box;
      /* Even Newer Dropshadow */
      
      box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.015);
      border-radius: 8px;
  
  
      
      font-family: var(--font-default);
      display: flex;
      justify-content: space-between;
      min-height: 165px;
      min-width: 700px;
      border-radius: var(--card-border-radius);
      padding: var(--card-padding);
      background-color: white;
      box-shadow: var(--box-shadow);
      margin-bottom: 15px;
    
      .content {
        .top {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
    
          .controls {
            display: flex;
            gap: 5px;
    
            .external {
              width: 22px;
              height: 22px;
              cursor: pointer;
            }
    
            .dropdown {
              background-color: none;
    
              img {
                width: 22px;
                height: 22px;
              }
    
              > button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                background-color: rgba(0,0,0,0);
                border: 0;
                outline: 0;
              }
            }
          }
        }
    
        .title {
          font-size: 18px;
          color: var(--font-color);
          font-weight: 600;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-shadow: rgba(0, 0, 0, .05) 0 0 1px;
        }
    
        .snippet {
          font-size: 14px;
          line-height: 135%;
          color: var(--font-color-muted);
        }
      }
    
      .img {
        margin-left: 20px;
        object-fit: cover;
        border-radius: 4px;
        width: 315px;
      }
    }
  
    input[type=checkbox] {
      transform: scale(1.5);
    }
  
    select{
      transform: scale(1.2);
    }
  