// CSS variables
:root {
  --font-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --font-color: #283e59;
  --font-color-muted: #95aac9;
  --font-color-light: #b8b8b8;
  --font-color-link: #0066cb;
  --font-color-link-visited: #006bff;
  --font-size: 15px;

  --focus-color: #2684ff;
  --focus-background: #b9d7ff;

  --bg-color: #f9fbfd;
  --box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);

  --card-padding: 25px;
  --card-border-radius: 8px;
  --card-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);

  --border-color-dark: #d2ddec;
  --border-color-light: #edf2f9;

  --input-border-radius: 6px;

  // LabeledText
  --label-weight: 400;
  --text-weight: 700;

  // Theme
  --theme-primary: #0652dd;
  --theme-light: #ffffff;

  // Button
  --button-border-color: #e3ebf6;
  --button-text-color-light: #283e59;
}

// SASS variables
$font-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$font-color: #283e59;
$font-color-muted: #95aac9;
$font-color-light: #b8b8b8;
$font-color-link: #0066cb;
$font-color-link-visited: #006bff;
$font-size: 15px;

$focus-color: #2684ff;
$focus-background: #b9d7ff;

$box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
$bg-color: #f9fbfd;

$border-color-dark: #d2ddec;
$border-color-light: #edf2f9;
