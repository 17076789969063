.changeuserpage {
  background: #ffffff;
  border-radius: 20px;

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

  margin-left: 25%;
  width: 800px;
}

.changeuserpage h2 {
  background: #e5e5e5a1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-weight: 400;
  padding: 20px 10px;
}

.changeuserform {
  margin-left: 10%;
  max-width: 70%;
}

.changeuserdetails {
  margin-bottom: 20px;

  border-bottom: 1px solid rgb(194, 194, 194);

}

#changedetails{
     /* padding: 2rem 2rem; */
      border: none;

     margin-top: 10px;
     text-decoration: none;
     background: #0069ed;
     color: #ffffff;

}