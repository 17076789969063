label {
  margin: 10px;
}
.balkerne button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.balkerne button:hover,
.balkerne button:focus {
  background: #0053ba;
}

.balkerne button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.balkerne button:active {
  transform: scale(0.99);
}
