.media-item {
  position: relative;

  background: #ffffff;
  border-radius: 10px;
  width: calc(100% - 500px);
  height: 105px;
  left: 320px;
  top: 130px;
  margin-bottom: 30px;

  display: grid;
  grid-template-columns: max-content max-content auto 0.4fr;
}

.sourcelogo {
  margin-left: 15px;
  margin-top: 15px;
  grid-row-start: span 2;
}

.icon {
  max-height: 60px;
  max-width: 150px;
  margin-right: 20px;
  width: auto;
  height: auto;
}

.sourcename {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;

  padding: 0px 0px;
  color: #000000;
  vertical-align: top;
}

.handle {
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 10px;
  color: #0041f5;
}
.contentdescription {
  grid-column-start: span 3;
}
.date {
  font-size: 15px;
  text-align: right;
  margin-right: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
  border-radius: 10px;
}

.overlay a {
  color: white;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  left: 45%;
  top: 25px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.media-item:hover .overlay {
  opacity: 0.7;
}
