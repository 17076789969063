.summary {
  margin-top: 50px;
  width: 950px;
  max-height: 400px;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 15px 20px;
}

.header {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  &__title {
    padding: 10px 10px;
    margin: 0;
  }

  &__selected {
    position: relative;
    margin-left: 10px;
    font-size: 14px;
  }

  &__filters {
    display: flex;
    flex-flow: row;
    margin-left: auto;
  }
}

.historicalertsectioncontent {
  margin: 25px;
  display: grid;
  grid-template-columns: 200px auto;
}

.historicalertsummarycontent {
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin: 30px;
}

.historicalertnotices {
  display: inline-block;
  text-align: center;
}

.historicalertimage {
  max-height: 120px;
  max-width: 170px;
  cursor: pointer;
}

.historicalertimage:hover {
  transform: scale(1.1);
}

.historiclaertdescription p {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.7);
}

.dropdown-button {
  padding-left: 10px;

  button {
    user-select: none;
    justify-self: center;
    height: 30px;
    width: 120px;
    font-size: 14px;
    outline: none;
    border: none;
    background-color: #e8e8e8;
    border-radius: 100px;

    &:hover {
      border: 1px solid grey;
    }
  }
}
