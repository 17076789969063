.Portfoliosearch {
  width: calc(100% - 50px);
  height: 140px;

  background: #ffffff;
  border-radius: 20px;

  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

  display: grid;
  grid-template-rows: 5px 70px 70px;
}

.Portfoliosearch h3 {
  margin: 20px;
  font-size: 20px;
  text-align: center;
}

.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  height: 42px;
  width: calc(100% - 30px);
  padding: 0px 5px;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;

  width: calc(100% - 50px);
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.propertysearch,
.propertysearchlistbutton {
  width: max-content;
  height: 42px;
  margin-top: 0;
  font-size: 0.8em;
  color: #eeeeee;
  background: #0652dd;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.propertysearch:hover {
  background: #2166e6;
}
/* .propertysearchlistbutton {
  width: max-content;
  height: 42px;
  margin-top: 0;

  color: #eeeeee;
  background: #0652dd;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} */

.locationlistbutton:hover {
  background: #2166e6;
}

#refreshicon {
  text-align: right;
  margin: 10px;
}

.search-input-area {
  display: grid;
  grid-template-columns: auto 120px 120px;
  gap: 15px;
  margin-right: 20px;
}

@media (max-width: 600px) {
  .search-input-area {
    grid-template-columns: auto;
    margin-left: 20px;
  }

  .Portfoliosearch {
    height: 240px !important;
  }
}
