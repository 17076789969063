.propertydetails {
  display: grid;
  grid-template-columns: 30% 20% auto;
  grid-column-gap: 20px;
  margin-left: 20px;
  margin-right: 20px;

  border-bottom: 0.5px solid rgba(200, 214, 229, 1);
}

#property-remove-button {
  border: none;
  max-width: 10px;
  float: right;
  margin-top: 0;
  color: darkgrey;
}
.full_name,
.phone,
.email,
.position,
.group,
.name,
.location-search-input,
.address-lookup-select,
.address-lookup-input,
.building_no,
.country {
  display: inline-block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.full_name:focus,
.phone:focus,
.email:focus,
.position:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

#wrapper {
  text-align: center;
  margin-top: 50px;
}
#termscheckbox {
  display: inline-block;
  margin: 20px;
  width: 20px; /*Desired width*/
  height: 20px; /*Desired height*/
}
