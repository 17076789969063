#root {
  height: 100%;
}

.opsapp {
  position: relative;

  height: calc(100% - 70px);
}

.pagelayout {
  height: 100%;
  display: grid;
  grid-template-columns: auto 200px;
}

.boardleft {
  display: grid;
  grid-template-rows: auto 280px;
}

.opsSideBar {
  background: #ffffff;
  width: 200px;
  right: 0;
  /* height: 100%; */
}
.opsBubbleContainer {
  position: absolute;
  top: 100px;
  right: 30px;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 50px;
}
.opsBubbleContainer h6 {
  margin: 0;
  font-size: 18px;
  margin-top: 5px;
}
.bubbleCritical {
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #8b0000;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}

.bubbleWarning {
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #e50000;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}

.alertbox {
  top: 25%;
    left: 35%;
  padding: 20px;
  background: rgb(209, 95, 95);
  border-radius: 5px;
  width: 30%;
  position: fixed;
  z-index: 999999;


}

.bubbleAlert {
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #ffa500;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}

.bubbleNews{
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #78AB46	;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}
.bubbleAll{
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 8px;
  background: #0652dd;

  color: #ffffff;
  text-align: center;
  float: left;
  margin: 7px;
}

.mediaBar {
  /* position: absolute; */
  width: 100%;
  height: 300px;
  bottom: 0;
  background: #fafafaaf;

  overflow-y: scroll;
  overflow-x: hidden;
}

.mediaBar_title {
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
  padding: 4px 0;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 2px solid rgba(139, 217, 254, 1);
  width: 100%;
}

.media_content {
  display: grid;
  grid-template-columns: 45% 50%;
  grid-column-gap: 5%;
}

.media_item {
  position: relative;
  width: 90%;
  /* height: auto; */
  left: 25px;

  background: #ffffff;
  /* border: 1px solid #8b8b8b; */
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

  display: grid;
  grid-template-columns: 80px auto 10% 15%;
  grid-column-gap: 20px;
}

.media_headline {
  font-size: 15px;
  padding-top: 5px;
}

.media_icon {
  align-content: right;
  max-height: 30px;
  max-width: 100px;
  margin-right: 20px;
  margin: 10px;
}

.media_date {
  text-align: right;
  margin-right: 20px;
  font-size: 12px;
  vertical-align: center;
}

.newsoverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
  border-radius: 10px;
  text-align: center;
}

.newsoverlay a {
  color: white;
  font-size: 20px;
  font-weight: bold;
  left: 45%;
  margin-top: 10px;
  text-decoration: none;
}

.media_item:hover .newsoverlay {
  opacity: 0.7;
}

.remove-button {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  border: 0;
  outline: 0;
  transition: background-color .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-weight: 500;
  font-size: 14px;
  padding: 5px 10px;
}

.remove-button:hover {
  background-color: #ff8a8a;
}
