.container {
  display: flex;
  flex-flow: column;
  gap: 5px;
  min-width: 200px;

  .label {
    color: var(--font-color);
    font-size: 15px;
    font-weight: 400;
  }

  .error {
    font-size: 10px;
    font-weight: 400;
    margin-left: 5px;
    color: #ff746d;
  }

  .input {
    color: #363636;
    background-color: white;
    min-height: 40px;
    outline: 0;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--border-color-light);
    // padding-left: 12px;
    font-size: 15px;

    & > div {
      border: 0;
      border-radius: var(--input-border-radius);
      box-shadow: 0;
    }

    &::placeholder {
      color: #d4d4d4;
    }
  }
}