.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  overflow-y: scroll;
  overflow-x: hidden;
}
.formbackground {
  position: absolute;
  left: 25%;
  width: 600px;
  top: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}

.formcontent {
  margin-left: 10%;
  margin-top: 30px;
  max-width: 70%;
}

.form-group {
  margin-bottom: 30px;
}
.form-group button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-group button:hover,
.form-group button:focus {
  background: #0053ba;
}

.form-group button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.form-group button:active {
  transform: scale(0.99);
}

.form-group-date {
  display: block;
}

.react-datepicker-wrapper {
  border-radius: 5px;
  margin-bottom: 20px;
}

.react-datepicker {
  transform: scale(1.5, 1.5);
  transform-origin: left top;
}
.submitbutton {
  text-align: center;
}

.close_cross_button {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 0;
  border: none;
  background: none;
}
