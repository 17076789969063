.button {
  display: inline-flex;
  font-family: var(--font-default);
  justify-content: center;
  align-items: center;
  min-width: 125px;
  
  padding: 0 25px;
  border-radius: 30px;
  
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: var(--theme-primary);
  cursor: pointer;
  transition: .1s all cubic-bezier(0.075, 0.42, 0.665, 1);

  &:hover {
    filter: brightness(120%);
  }
}

.large {
  height: 48px;
}

.normal {
  height: 35px;
}

.small {
  height: 28px;
  min-width: 55px;
  font-size: 12px;
  font-weight: 400;
}

.tiny {
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 400;
  min-width: 0;
}

.wide {
  display: flex;
}

.light {
  background-color: var(--theme-light);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color-light);

  &:hover {
    filter: brightness(98%);
  }
}

.disabled {
  filter: grayscale(30%) brightness(80%);
  opacity: .7;
  
  &:hover {
    filter: grayscale(30%) brightness(80%);
    opacity: .7;
  }
}

.light.disabled {
  filter: grayscale(0%) brightness(90%);
  
  &:hover {
    filter: grayscale(0%) brightness(90%);
  }
}



.downloadButton {
  color: inherit !important;
  text-decoration: none !important;
}