.Riskhighlights {
  width: 950px;
  min-height: 500px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Riskhighlightsheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.Riskcontent {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.riskmap {
  width: 276px;
  height: 221px;
  margin-top: 25px;
  margin-left: 50px;
}

.riskright {
  margin-left: 50px;
}

.riskdetailsection {
  display: grid;
  grid-template-rows: (2, auto);
  row-gap: 30px;
  justify-items: center;
  margin-right: 30px;
}

.risk_insight_text {
  background: #ffffff;
  border: 1px solid #e6d325;
  border-radius: 20px;
  margin-bottom: 50px;
}
.risk_insight_text p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.risk_insight_text img {
  width: 27px;

  margin: 0px 20px;
  vertical-align: text-top;
  align-items: left;
}

.mapkey {
  margin-left: 100px;
  text-align: center;
}

.riskhigh {
  font-size: 20px;
  color: #d63333;
}
.riskmedium {
  font-size: 20px;

  color: #d68533;
}
.risklow {
  font-size: 20px;

  color: #ad5cff;
}
.riskverylow {
  font-size: 20px;

  color: #5c5cff;
}

.historicfloodingkey {
  font-size: 20px;

  color: #005d82;
}

.riskdefinition {
  font-size: 20px;
}
