.containerAttachedImages {
    display: flex;
    flex-direction: row;
    max-width: 50%;
    align-items: center;
    border: 2px solid #9dceeb;
    border-radius: 8px;
    padding: 4px;
    padding-right: 5 px;
    padding-left: 5px;
    margin-right: 15px;
    margin-top: 15px;

    @media (max-width: 767px) {
       flex-grow: 1;
       max-width: 95%;
       justify-content: space-between;
       margin-right:auto;
       margin-left:auto;
    }

    @media (min-width: 767px) {
    display: flex;
    flex-direction: row;
    max-width: 50%;
    align-items: center;
    border: 2px solid #9dceeb;
    border-radius: 8px;
    padding: 4px;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 15px;
    margin-top: 15px;
    }
}

.containerSize{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 8px;
}

.close{
    display: flex;
    margin-bottom: auto;
    margin-top: auto;
    font-size: 1.5rem;
    color: #000;
    cursor: pointer;

    .closeSpan{
        height: 27px;
        width: 27px;
    }
}

.attachName{
    display:flex;
    margin-top:auto;
    margin-bottom: auto;
    margin-right: 8px;
}

.attachSize{
    display:flex;
    margin-top: auto;
    margin-bottom:auto;
    margin-right: 3px
}
