.Riskreportsection {
  width: 950px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  margin-bottom: 20px;
}

.Riskreportsectionheading {
  background: #e5e5e5;
  padding: 10px 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.alertcontent {
  display: grid;
  grid-template-columns: 50% 40%;
}

.alert-left {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 50px;

  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
}

.alertimage {
  margin-left: 25%;
  height: 117px;
}

.alertsection {
  width: 375px;
}

.alertsection p {
  left: 20px;
  top: 20px;

  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.7);
}

.recommednationsection {
  /* position: absolute; */
  width: 166px;
  /* left: 608px;
  top: 84px; */
}

.notice_text {
  position: relative;
  width: 430px;
  left: 30px;
  margin-bottom: 17px;

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
}

.notice_grid {
  display: grid;
  grid-template-columns: 50px auto;
  margin: 10px 20px;
}

.notice_text p {
  font-size: 15px;
  color: #000000;
}

.notice_text img {
  height: 27px;
  vertical-align: text-top;
}

.insight_text {
  position: relative;
  width: 430px;
  left: 50px;
  height: 132px;
  top: 40px;

  background: #ffffff;
  border: 1px solid #e6d325;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0px 0px;
}
.insight_text p {
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 20px 20px;
}

.insight_text img {
  width: 27px;
  height: 27px;
  margin: 25px 20px;
  vertical-align: text-top;
}

.timeraised {
  text-align: right;
}
