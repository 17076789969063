.textLine {
  font-family: var(--font-default);
  display: flex;
  flex-flow: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .label {
    font-size: 12px;
    font-weight: 500;
    color: var(--font-color-muted);
    margin-bottom: 5px;
  }

  .text {
    display: flex;
    gap: 5px;
    font-size: 15px;
    font-weight: 400;
    color: var(--font-color);
    text-shadow: rgba(0, 0, 0, .05) 0 0 1px;
    letter-spacing: .2px;
  }
  
  .rightAlign {
    text-align: right;
    justify-content: flex-end;
  }
}

.textBox {
  composes: textLine;

  .box {
    
  }
}
