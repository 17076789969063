#orgupload {
  margin-left: 10%;
  margin-right: 10%;

  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 50px;
}

.getstartedrisks {
  margin-left: 30px;
  margin-bottom: 30px;
}

.checkboxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 15px;
}
