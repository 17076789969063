.card {
    font-family: var(--font-default);
    display: flex;
    justify-content: space-between;
    min-height: 165px;
    border-radius: var(--card-border-radius);
    padding: var(--card-padding);
    background-color: white;
    box-shadow: var(--box-shadow);
    margin-bottom: 15px;
  
    .content {
      .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
  
        .controls {
          display: flex;
          gap: 5px;
  
          .external {
            width: 22px;
            height: 22px;
            cursor: pointer;
          }
  
          .dropdown {
            background-color: none;
  
            img {
              width: 22px;
              height: 22px;
            }
  
            > button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 22px;
              height: 22px;
              background-color: rgba(0,0,0,0);
              border: 0;
              outline: 0;
            }
          }
        }
      }
  
      .title {
        font-size: 18px;
        color: var(--font-color);
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: rgba(0, 0, 0, .05) 0 0 1px;
      }
  
      .snippet {
        font-size: 14px;
        line-height: 135%;
        color: var(--font-color-muted);
      }
    }
  
    .img {
      margin-left: 20px;
      object-fit: cover;
      border-radius: 4px;
      width: 315px;
    }
  }

  input[type=checkbox] {
    transform: scale(1.5);
  }

  select{
    transform: scale(1.2);
  }