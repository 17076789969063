.incident {
  font-family: var(--font-default);
  width: 950px;
  background: white;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: var(--box-shadow);

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .left {
      display: flex;
      gap: 25px;
    }

    .right {
      display: flex;
      gap: 15px;
    }
  }

  .description {
    font-size: 16px;
    color: var(--font-color);
  }

  .images {
    display: flex;
    gap: 5px;
    margin-top: 15px;
  }

  .img {
    height: 50px;
  }
}