.link {
    margin-bottom: auto;
    margin-top:auto;
    cursor: pointer;
    text-decoration: underline;
    &:hover{
        color:rgb(50, 50, 221);
    }

}

.menu a.option{
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    margin-left: 20px;
    &:hover{
        text-decoration: underline !important;
        color:mediumblue;
    }
}

.buttonDisabled{
    cursor: no-drop;
    color: gray;
    margin-left: 20px;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none !important;
    &:hover{
        color:gray;
    }
}

.Active {
    color:#43b31f;
    font-size: 14px;
    font-weight: 500;
}
 
.Inactive {
    color: #FFBF00;
    font-size: 14px;
    font-weight: 500;
}
